import FormPopup from "../../../../components/CustomerComponents/FormPopup";
import Currency from "../../../../components/CustomerComponents/Currency";
import Label from "../../../../components/CustomerComponents/Label";
import StepSubTitle from "../../../../components/CustomerComponents/StepSubtitle";
import { Typography, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import StepForwardButton from "../../../../components/CustomerComponents/StepForwardButton";
import { LeftOutlined } from "@ant-design/icons";
import CTA from "../../../../components/CustomerComponents/Cta";
import { COLORS } from "../../../../core/constants";
import {
  submitAdditionalQuestions,
  getAdditionalInfo,
} from "../../../../core/services";

const CovidForm = ({
  visible,
  onClose,
  isMobile,
  onSubmit,
  onBack,
  leadId,
}) => {
  const [turnover, setTurnover] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = () => {
    submitAdditionalQuestions(leadId, {
      covid_2019_turnover: turnover,
    });
    onSubmit();
  };

  useEffect(() => {
    async function getData() {
      const response = await getAdditionalInfo(leadId);
      const covidTurnover = response.data.covid_2019_turnover;
      if (covidTurnover) setTurnover(covidTurnover);
      setIsLoading(false);
    }

    getData();
  }, []);

  return (
    <FormPopup
      visible={visible}
      isMobile={isMobile}
      onClose={onClose}
      persistent={true}
    >
      <div
        className="single-page-form scrollbar light"
        style={{ minWidth: !isMobile && "560px" }}
      >
        <Typography.Title
          style={{
            fontSize: isMobile ? "18px" : "clamp(24px, 2.461vh, 28px)",
            marginTop: isMobile && "4px",
            marginBottom: "30px",
            marginTop: "32px",
            marginLeft: isMobile && "8px",
          }}
        >
          Covid 19 impact on Business
        </Typography.Title>
        <div
          style={{
            position: "relative",
            maxWidth: "560px",
            marginRight: !isMobile && "30px",
            padding: isMobile && "0 8px",
            margin: isMobile && "0 auto",
            pointerEvents: isLoading && 'none', 
            opacity: isLoading && '0.1'
          }}
        >
          <Typography.Text
            style={{
              fontWeight: "600",
              fontSize: isMobile ? '16px' : "18px",
              margin: "20px 0 0px",
              display: "block",
            }}
          >
            What was your turnover in 2019?
          </Typography.Text>
          <div
            style={{
              maxHeight: "74px",
              position: "relative",
              marginBottom: "40px",
              marginTop: "-4px",
              maxWidth: "536.250px",
            }}
          >
            <Label bg={"#fafafa"} label={"2019 Turnover"}></Label>
            <Currency
              key={isLoading}
              data={{ loan_amount: undefined }}
              setValue={(val) => setTurnover(val)}
              defaultValue={turnover}
            ></Currency>
          </div>
          <StepSubTitle
            style={{
              marginTop: "12px",
              maxWidth: "536.250px",
              height: "0px",
              position: "absolute",
              bottom: "0px",
              marginRight: isMobile && "8px",
            }}
            fontSize={"12px"}
            iconFontSize={"13px"}
          >
            If you don’t know the exact amount, a rough estimate is fine.
          </StepSubTitle>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              top: "140px",
            }}
          >
            <Spin />
          </div>
        )}
        <Row
          style={{
            margin: isMobile ? "60px auto 24px" : "60px 0 24px",
            maxWidth: "536.250px",
            padding: isMobile && "0 8px",
            pointerEvents: isLoading && 'none', 
            opacity: isLoading && '0.1'
          }}
          justify={"space-between"}
        >
          <CTA
            type="text"
            style={{
              padding: 0,
              fontSize: isMobile ? "15px" : "clamp(17px, 1.9444vh, 21px)",
              color: COLORS.darkGrey,
            }}
            ctaEnabled={true}
            onClick={onBack}
          >
            <LeftOutlined />
            Back
          </CTA>
          <StepForwardButton
            isMobile={isMobile}
            onClick={handleSubmit}
            enabled={turnover > 0}
          >
            Submit
          </StepForwardButton>
        </Row>
      </div>
    </FormPopup>
  );
};

export default CovidForm;
