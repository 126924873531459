import { snakeCase, range } from "lodash";
import {
  Input,
  Form,
  Typography,
  Select,
  Col,
  Spin,
  ConfigProvider,
  Row,
  Progress,
} from "antd";
import { useEffect, useRef, useState, useCallback } from "react";
import PhoneNumberInfo from "../../components/CustomerComponents/PhoneNumberInfo";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";

import Timer from "../../components/CustomerComponents/Timer";
import Currency from "../../components/CustomerComponents/Currency";
import LoanAmount from "../../components/CustomerComponents/LoanAmount";
import BusinessData from "../../components/CustomerComponents/BusinessData";
import StepForwardButton from "../../components/CustomerComponents/StepForwardButton";
import AcceptTerms from "../../components/CustomerComponents/AcceptTerms";
import CTA from "../../components/CustomerComponents/Cta";
import Address from "../../components/CustomerComponents/Address";
import DateOfBirth from "../../components/CustomerComponents/DateOfBirth";
import AddressManual from "../../components/CustomerComponents/AddressManual";
import MobileNumber from "../../components/CustomerComponents/MobileNumber";
import RadioList from "../../components/CustomerComponents/RadioList";
import YesNo from "../../components/CustomerComponents/YesNo";
import Label from "../../components/CustomerComponents/Label";
import CustomerSidebar from "../../components/CustomerComponents/CustomerSidebar";
import OTP from "../../components/CustomerComponents/OTP";
import StepSubTitle from "../../components/CustomerComponents/StepSubtitle";
import Error from "../../components/CustomerComponents/Error";
import Email from "../../components/CustomerComponents/Email";

import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import customParseFormat from "dayjs/plugin/customParseFormat";

import Validator from "Validator";
import { LeftOutlined } from "@ant-design/icons";

import { isValid as postcodeCheck } from "postcode";
import { getName, makeHandleFocus } from "../../core/utils";
import { validatePhone, getCompanyData } from "../../core/services";
import useMediaQuery from "../../core/hooks/useMediaQuery";
import useBlockZoom from "../../core/hooks/useBlockZoom";
import { otpResend, otpSend, otpVerify } from "./services";

import {
  COLORS,
  defaultDateFormat,
  THEME,
  I_AM_NOT_ONE_OF_THESE_PEOPLE,
  isMobileMediaQuery,
  URLS,
  externalBaseURL,
  affiliateStyleSheetMap,
} from "../../core/constants";

const { Text, Title } = Typography;

// if (typeof document === "undefined") {
//   // running in a server environment
// } else {
// }

dayjs.locale("en-gb");
dayjs.extend(customParseFormat);

function BusinessType(props) {
  const list = ["Sole Trader", "Partnership"];

  return <RadioList optionType="button" list={list} {...props} />;
}

function StepTitle({ text, isMobile, embeded }) {
  return (
    text && (
      <div>
        <Col>
          <Title
            style={{
              margin: embeded && !isMobile ? "0 0 3.6vh" : "0 0 2.96vh",
              fontSize: isMobile
                ? "18px"
                : embeded
                  ? "clamp(24px, 2.461vh, 28px)"
                  : "clamp(18px, 2.461vh, 28px)",
            }}
            level={2}
          >
            {text}
          </Title>
        </Col>
      </div>
    )
  );
}

function Field({
  label,
  name,
  value,
  type,
  children,
  onKeyPress,
  fieldProps,
  Component,
  renderErrors,
  shortMargin,
  isMobile,
  decreaseBottomSpacing,
  embeded,
  ...props
}) {
  Object.assign(props, fieldProps);

  const { onChange, title, subtitle, errors } = props;

  name = name || snakeCase(label);

  return (
    <Row>
      <Col span="24">
        <StepTitle isMobile={isMobile} text={title} embeded={embeded} />

        <StepSubTitle
          isMobile={isMobile}
          fontSize={isMobile ? "12.4px" : "clamp(13.6px, 1.388vh, 15px)"}
          style={{
            marginBottom: "16px",
          }}
        >
          {subtitle}
        </StepSubTitle>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24} style={{ order: 1, zIndex: 0 }}>
            {label && <Label label={label} isMobile={isMobile} />}
            {children}
            {!Component && (
              <Input
                onKeyPress={onKeyPress}
                style={{ width: "100%" }}
                onChange={onChange}
                value={value}
                name={name}
                onFocus={makeHandleFocus(isMobile)}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            {renderErrors !== false ? (
              <Error
                errors={errors}
                shortMargin={shortMargin}
                isMobile={isMobile}
                decreaseBottomSpacing={decreaseBottomSpacing}
              />
            ) : (
              <div style={{ height: "60px" }}></div>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

function BusinessAge(props) {
  const list = [
    "Less than 1 year",
    "1 - 2 years",
    "2 - 3 years",
    "More than 3 years",
  ];

  return <RadioList optionType="button" list={list} {...props} />;
}

const residentialStatusList = [
  "Yes, with a mortgage",
  "Yes, without a mortgage",
  "No",
];
function ResidentialStatus(props) {
  return (
    <RadioList
      label="Residential Status"
      list={residentialStatusList}
      {...props}
    />
  );
}

const directorHomeStatusList = ["Yes", "No"];
function DirectorHomeStatus(props) {
  return (
    <RadioList
      label="Residential Status"
      list={directorHomeStatusList}
      {...props}
    />
  );
}

function DirectorName(props) {
  const [loading, setLoading] = useState(true);
  const [cid, setCid] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setCid(window.sessionStorage.getItem("company"));
  }, []);

  const hadleCompanyData = async () => {
    setLoading(true);
    const data = await getCompanyData(cid || props.companyId);
    if (data) {
      setList(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    hadleCompanyData();
  }, [cid]);

  return (
    <Spin spinning={loading}>
      <RadioList list={list} optionType="button" {...props} />
    </Spin>
  );
}

function Agent() {
  const list = range(1, 10).map((n) => "Agent " + n);

  return <SelectList label="Agent" list={list} />;
}

const jobPositionList = [
  // "Director",
  "Employee",
  "Accountant",
  "Owner",
  "Partner",
  "Other",
];

function JobPosition(props) {
  return <RadioList label="Job Position" list={jobPositionList} {...props} />;
}

const purposeList = [
  "Business Growth",
  "Additional Cashflow",
  "Stock & Inventory Purchase",
  "Plant & Machinery Purchase",
  "Vehicle Purchase",
  "Existing Loan Refinance",
  "Other Purposes",
];
function Purpose(props) {
  return <RadioList label="Purpose" list={purposeList} {...props} />;
}

const cardAmounts = ["up to 25%", "25% - 50%", "50% - 75%", "more than 75%"];

function CardAmount(props) {
  return <RadioList label="Amount" list={cardAmounts} {...props} />;
}

function SelectList({ label, name, list, fieldProps, ...props }) {
  name = name || snakeCase(label);

  name = getName({ label, name });

  function handleChange(e) {
    props.setValue(e);
  }

  const items = list.map((item) => {
    return {
      value: snakeCase(item),
      label: item,
    };
  });

  return (
    <Select
      style={{ width: "100%" }}
      name={name}
      onChange={handleChange}
      {...props}
      options={items}
      placeholder="Select"
    ></Select>
  );
}

function BusinessNameManual(props) {
  function manualInput(e) {
    props.setValue(e.target.value);
  }

  return (
    <Input
      onChange={manualInput}
      onFocus={makeHandleFocus(props.isMobile)}
      {...props}
    />
  );
}

function validateValue(value, rule) {
  const name = "random";
  const data = {};
  data[name] = value;

  const vRules = {};
  vRules[name] = rule;
  const v = Validator.make(data, vRules);
  return v.passes() ?? false;
}

function DirectorNameManual({ data, onBeforeInput, setValue, isMobile }) {
  const [first_name, setFirstName] = useState(
    data?.director_name_manual?.first_name ?? ""
  );
  const [last_name, setLastName] = useState(
    data?.director_name_manual?.last_name ?? ""
  );

  useEffect(() => {
    setValue({ first_name, last_name });
  }, [first_name, last_name, setValue]);

  return (
    <Row>
      <Col span="24" style={{ marginBottom: "clamp(15px, 1.88vh, 25px" }}>
        <Label label="First Name" />
        <Input
          value={first_name}
          onChange={(e) => setFirstName(e.target.value)}
          onBeforeInput={onBeforeInput}
          onFocus={makeHandleFocus(isMobile)}
        />
      </Col>
      <Col span="24">
        <Label label="Last Name" />
        <Input
          value={last_name}
          onChange={(e) => setLastName(e.target.value)}
          onBeforeInput={onBeforeInput}
          onFocus={makeHandleFocus(isMobile)}
        />
      </Col>
    </Row>
  );
}

function AcceptCardPayments(props) {
  return <YesNo {...props} />;
}

function Invoices(props) {
  return <YesNo {...props} />;
}

function PropertyValue(props) {
  return Currency({ label: "Property Value", ...props });
}

const stepsList = {
  loan_amount: {
    id: "loan_amount",
    index: 1,
    title: "How much would you like to borrow?",
    label: "Loan Amount",
    buttonText: "Find your loan",
    buttonIcon: false,
    buttonMedium: true,
    Component: LoanAmount,
    next: "purpose",
    validation: {
      keyRule: [],
      fieldRule: [],
      rules: ["required", "numeric", , "min:5000", "max:5000000"],
      messages: "Loan amount between £5,000 to £5,000,000",
    },
    // back: false,
    optional: false,
  },
  purpose: {
    index: 2,
    id: "purpose",
    title: "What do you need this loan for?",
    Component: Purpose,
    next: "business_name",
    validation: {
      rules: [
        "required",
        "in:" + purposeList.map((p) => snakeCase(p)).join(","),
      ],
    },

    // back: ['loan_amount'],
    shortMargin: true,
    optional: false,

    auto_next: true,
  },
  business_name: {
    index: 3,
    id: "business_name",
    title: "What is your business name?",
    Component: BusinessData,
    validation: {
      keyRule: [],
      rules: ["required", 'business_data'],
      messages: "",
    },
    next: "business_name_manual",
    jump_allowed_if: ["not_in:My business is not listed here"],
    next_step_if_valid: "mobile_number",

    // back: ['purpose'],
    shortMargin: true,
    optional: false,
  },
  business_name_manual: {
    index: 3,
    id: "business_name_manual",
    title: "Type your business name",
    label: "Business Name",
    Component: BusinessNameManual,
    validation: {
      keyRule: [`regex:/[a-zA-Z0-9. ,&\-]/`],
      rules: [
        "required_if:business_name,My business is not listed here",
        `regex:/[a-zA-Z0-9. ,&\-]/`,
        "min:3",
      ],
      messages: "Type a valid business name",
    },
    // back: ['business_name_manual'],
    next: "business_type",
  },
  business_type: {
    index: 3,
    id: "business_type",
    title: "What is your business type?",
    parent: "business_name",
    // label: "Business Type",
    name: "business_type",
    Component: BusinessType,
    next: "business_age",
    validation: {
      rules: ["required_with:business_name_manual"],
    },

    // back: ['business_name_manual', 'business_name'],

    optional: true,
    auto_next: true,
  },
  business_age: {
    index: 3,
    id: "business_age",
    title: "How long you've been trading for?",
    // label: "Business Age",
    Component: BusinessAge,
    next: "mobile_number",
    validation: {
      keyRule: false,
      rules: ["required_with:business_name_manual"],
    },
    parent: "business_name",
    // back: ['business_type'],

    optional: true,
    auto_next: true,
  },
  mobile_number: {
    index: 4,
    id: "mobile_number",
    title: "What is your mobile number?",
    label: "Mobile Number",
    Component: MobileNumber,
    next: "otp",
    validation: {
      keyRule: ["numeric"],
      rules: ["mobile", "digits:11"],
      messages: {
        digits: "Please provide a valid mobile number",
      },
    },
    // back: ['business_age'],
    auto_next: false,
    next_step_if_valid: "revenue",
    jump_allowed_if: ["mobile_jump"],
    optional: false,
  },
  otp: {
    index: 5,
    id: "otp",
    title: "Verify your mobile number",
    // label: "OTP",
    Component: OTP,
    name: "otp",
    next: "revenue",
    validation: {
      keyRule: ["numeric"],
      rules: ["required", "digits:11"],
      messages:
        "The code you entered is invalid. Please enter the correct code.",
    },
    optional: false,
    renderErrors: false,
    back: false,
    auto_next: true,
    // back: ['otp'],
  },
  revenue: {
    index: 6,
    id: "revenue",
    title: "How much is the total annual sales/turnover of your business?",
    subtitle:
      "Don't worry if you don't know the exact amount, just a rough figure is fine",
    Component: Currency,
    next: "accept_card_payments",
    validation: {
      keyRule: ["numeric"],
      rules: ["required", "numeric", "min:1"],
    },
    messages: "Annual sales/turnover should be greater than £0",
    decreaseBottomSpacing: true,
    // back: ['mobile_number'],
    auto_next: false,
    optional: false,
  },
  accept_card_payments: {
    index: 7,
    id: "accept_card_payments",
    title: "Does your business accept card payments?",
    // label: "Accept Card Payments",
    Component: AcceptCardPayments,
    validation: {
      keyRule: false,
      rules: ["required"],
    },

    // back: ['revenue'],
    next: "card_amount",
    optional: false,
    auto_next: true,

    next_step_if_valid: "invoices",
    jump_allowed_if: ["in:no"],
  },
  card_amount: {
    index: 7,
    id: "card_amount",
    title: "How much of your business sales come from card payments?",
    Component: CardAmount,
    validation: {
      keyRule: false,
      rules: ["required_if:accept_card_payments,yes"],
    },
    parent: "accept_card_payments",

    // back: ['accept_card_payments'],

    optional: true,
    next: "invoices",
    auto_next: true,
  },
  invoices: {
    index: 8,
    id: "invoices",
    title: "Does your business issue invoices to other businesses?",
    // label: "Invoices",
    Component: Invoices,
    validation: {
      keyRule: false,
      rules: ["required"],
    },

    // back: ['card_amount'],company_exists_check
    next: "postcode",

    next_step_if_valid: "director_name",
    jump_allowed_if: ["company_exists_check"],
    next_step_if_invalid: "postcode",

    optional: false,
    auto_next: true,
  },
  postcode: {
    index: 9,
    id: "postcode",
    title: "What is your business trading address?",
    label: "Postcode",
    Component: Address,
    validation: {
      keyRule: ["alpha_num"],
      rules: ["postcode"],
      messages: "Type a valid post code",
    },

    next: "postcode_manual",

    next_step_if_valid: "director_name",

    next_steps: [
      {
        step: "postcode_manual",
        skip_if: ["postcode_jump"],
      },
      {
        step: "director_name_manual",
        skip_if: ["company_exists_check"],
      },
      {
        step: "director_name",
      },
    ],

    jump_allowed_if: ["postcode_jump"],

    // next_step_if_invalid: "director_name_manual",
    // back: ['invoices'],
    auto_next: false,
    optional: false,
    shortMargin: true,
  },

  postcode_manual: {
    index: 9,
    id: "postcode_manual",
    title: "What is your business trading address?",
    // label: "Postcode",
    Component: AddressManual,
    validation: {
      keyRule: [`regex:/[a-zA-Z0-9 ,-]/`],
      rules: ["postcode_manual"],
      // "messages": "All fields are required."
    },

    // back: ['postcode'],

    next: "director_name",
    optional: false,
    auto_next: false,
    next_steps: [
      {
        step: "director_name_manual",
        skip_if: ["company_exists_check"],
      },
      {
        step: "director_name",
      },
    ],
  },
  director_name: {
    index: 10,
    id: "director_name",
    title: "Which director are you?",
    Component: DirectorName,
    validation: {
      keyRule: [`regex:/[a-zA-Z0-9. ,-]/`],
      rules: ["required_unless:business_name,My business is not listed here"],
    },

    next: "director_name_manual",

    next_step_if_valid: "dob",
    jump_allowed_if: [
      "not_in:I am not one of these people",
      "required_if:business_name,My business is not listed here",
    ],

    // back: ['postcode'],
    auto_next: true,
    optional: false,
  },
  director_name_manual: {
    index: 10,
    id: "director_name_manual",
    title: "Type your name",
    Component: DirectorNameManual,
    next: "job_position",
    validation: {
      keyRule: [`regex:/[-',.A-Za-z ]`],
      rules: [
        "required_if:director_name,I am not one of these people",
        "director_name_manual",
      ],
      messages: "Please enter a valid name.",
    },
    optional: false,
  },
  job_position: {
    index: 10,
    id: "job_position",
    title: "What is your position in the business?",
    Component: JobPosition,
    next: "dob",
    validation: {
      keyRule: false,
      rules: [
        "required_if:director_name,I am not one of these people",
        "in:" + jobPositionList.map((i) => snakeCase(i)),
      ],
    },
    parent: "director_name",
    optional: true,
    auto_next: true,
  },
  dob: {
    index: 11,
    id: "dob",
    title: "Your date of birth",
    label: "DD/MM/YYYY",
    Component: DateOfBirth,
    next: "residential_postcode",
    validation: {
      keyRule: ["numeric"],
      rules: ["dob", "age", "dob_length"],
      singleErrorMessage: false,
      messages: {
        dob: "Please provide a valid date of birth",
        age: "Age should be between 18 and 100.",
        dob_length: "",
      },
    },
    optional: false,
  },
  residential_postcode: {
    index: 12,
    id: "residential_postcode",
    title: "What is your residential address?",
    label: "Postcode",
    Component: Address,
    validation: {
      keyRule: [`regex:/[a-zA-Z0-9. ,-]/`],
      rules: ["required", "postcode"],
      messages: "Type a valid post code",
    },
    next: "residential_postcode_manual",
    next_step_if_valid: "residential_status",
    jump_allowed_if: ["postcode_jump"],
    optional: false,
    shortMargin: true,
  },
  residential_postcode_manual: {
    index: 12,
    id: "residential_postcode_manual",
    title: "What is your residential address?",
    // label: "Postcode",
    Component: AddressManual,
    validation: {
      keyRule: [`regex:/[a-zA-Z0-9 ,-]/`],
      rules: ["postcode_manual"],
    },
    next: "residential_status",
    optional: false,
  },
  residential_status: {
    index: 13,
    id: "residential_status",
    title: "Are you a homeowner?",
    // label: "Property Value",
    Component: ResidentialStatus,
    next: "property_value",
    validation: {
      keyRule: false,
      rules: ["required"],
    },
    optional: false,
    auto_next: true,
    next_step_if_valid: "other_director_home_status",
    jump_allowed_if: ["in:no"],
  },
  other_director_home_status: {
    index: 13,
    id: "other_director_home_status",
    title: "Any other Director a homeowner?",
    Component: DirectorHomeStatus,
    next: "email",
    validation: {
      keyRule: false,
      rules: ["required_if:residential_status,no"],
    },
    optional: false,
    auto_next: true,
  },
  property_value: {
    index: 13,
    id: "property_value",
    title: "What is the current value of your property?",
    subtitle:
      "Don't worry if you don't know the exact amount, just a rough figure is fine",
    Component: PropertyValue,
    validation: {
      keyRule: ["numeric"],
      rules: ["required_if:residential_status,yes", "numeric"],
    },
    parent: "residential_status",
    optional: true,
    auto_next: false,
    next: "email",
  },
  email: {
    index: 14,
    id: "email",
    title: "Your email address",
    subtitle:
      "We'll notify you of changes and updates about your application, new products and features.",
    label: "Email",
    validation: {
      keyRule: [`regex:/[0-9a-zA-Z-_.@\\+]/`],
      rules: ["required", "email"],
    },
    Component: Email,
    next: "accept_terms",
    optional: false,
    decreaseBottomSpacing: true,
  },
  accept_terms: {
    index: 15,
    id: "accept_terms",
    name: "accept_terms",
    title: "By clicking Find your loan:",
    Component: AcceptTerms,
    validation: {
      keyRule: false,
      rules: [],
    },
    buttonIcon: false,
    buttonLarge: true,
    optional: false,
    isFinalStep: true,
    shortMargin: true,
    buttonText: "Find your loan",
  },
};

function age_validation(name, value) {
  if (!value) return false;
  const complete = String(value).length === 10;
  if (!complete) return true;

  const year = dayjs(value, defaultDateFormat).get("y");
  const age = new Date().getFullYear() - year;

  // if (age < 18 || age > 150) {
  //   return false;
  // }
  return age > 18 && age < 100;
}

function dob_validation(name, date, params) {
  if (!date) return false;

  const complete = String(date).length === 10;
  if (!complete) return true;

  return dayjs(date, defaultDateFormat, true).isValid();
}

function dob_length_validation(name, date) {
  const complete = String(date).length === 10;
  return complete;
}

function mobile_validation(name, value, params) {
  const data = {};
  data[name] = value;

  //rules
  const defaultRule = ["required", "numeric"];
  const rule7 = ["required", "digits:10"];
  const rule07 = ["required", "numeric"];

  const rules = {};
  rules[name] = defaultRule;

  const first = Validator.make(data, rules);

  if (!first.passes()) {
    return false;
  }

  const parts = value.split("");

  const startsWith = parts[0] === "0" && parts[1] === "7";

  if (!startsWith) {
    return false;
  }

  rules[name] = rule07;
  const v = Validator.make(data, rules);

  return v.passes();
}

const rules = {};
const messages = {};

Object.keys(stepsList).forEach((name) => {
  const step = stepsList[name];
  rules[name] = step.validation.rules;

  if (step.validation.messages || step.validation.singleErrorMessage) {
    //show only one message for each rule
    if (typeof step.validation.messages === "string") {
      step.validation.rules.forEach((rule) => {
        let fixedRule = rule.split(":")[0];
        messages[name + "." + fixedRule] = step.validation.messages;
      });
    } else {
      //show individual messages
      Object.keys(step.validation.messages).forEach((key) => {
        messages[name + "." + key] = step.validation.messages[key];
      });
    }
  }
});

function mobileJumpCheck(name, value) {
  //otp stores the number verified
  return this.data["otp"] === value;
}

function company_exists(name, value, params) {
  const companyExists =
    this.data["business_name"] !== undefined &&
    this.data["business_name"] !== "My business is not listed here";
  return companyExists;
}

function postcodeJumpCheck(name, value) {
  let jump = false;

  try {
    const obj = JSON.parse(value);

    jump = obj.postcode && obj.address && obj.address !== false;
  } catch (e) { }

  return jump;
}

function director_name_manual_validation(name, data) {
  if (!data) return false;

  if (Object.keys(data).length === 0) {
    return false;
  }

  const rules = {
    first_name: ["required", "regex:/[a-z - ',.]/i"],
    last_name: ["required", "regex:/[a-z - ',.]/i"],
  };

  const v = Validator.make(data, rules);
  return v.passes();
}

function postcode_manual_validation(name, data) {
  if (!data) return false;

  if (Object.keys(data).length === 0) {
    return false;
  }
  const rules = {
    postcode: ["required", "postcode"],
    house_number: [
      "required_without_all:house_name,flat_number",
      "regex:[a-zA-Z0-9 ]",
    ],
    house_name: [
      "required_without_all:house_number,flat_number",
      "regex:[a-zA-Z0-9 ]",
    ],
    flat_number: [
      "required_without_all:house_name,house_number",
      "regex:[a-zA-Z0-9 ]",
    ],
    street: ["required", "regex:[a-zA-Z0-9 ]", "min:1"],
    city: ["required", "regex:[a-zA-Z0-9 ]", "min:1"],
    county: ["regex:[a-zA-Z0-9 ]"],
  };

  const v = Validator.make(data, rules);
  v.extend("postcode", postcode_validation, "Please enter a valid postcode");

  return v.passes();
}

function director_validation(name, value) {
  if (
    name === "postcode" &&
    this.data["business_name"] &&
    this.data["business_name"] !== "My business is not listed here"
  ) {
    return true;
  }
}

function businessDataValidation(name, value, data) {
  return value === data?.company?.name
};

function postcode_validation(name, value, data) {
  if (
    name === "postcode" &&
    this.data["business_name"] &&
    this.data["business_name"] !== "My business is not listed here"
  ) {
    return true;
  }

  if (!value) {
    return false;
  }

  let valid = postcodeCheck(value);

  if (!valid) {
    let isJson = false;

    try {
      const obj = JSON.parse(value);

      isJson = obj.postcode !== undefined && obj.address !== undefined;

      if (isJson && obj.address === "My address is not listed here") {
        return true;
      }

      isJson = isJson && obj.address !== "My address is not listed here";
    } catch (e) {
      isJson = false;
    }

    valid = isJson;
  }

  return valid;
}

function getValidator(data, customRules, customMessages) {
  customRules = customRules || rules;
  customMessages = customMessages || messages;
  const v = Validator.make(data, customRules, customMessages);

  v.extend(
    "mobile",
    mobile_validation,
    "Please provide a valid mobile number starting with 07"
  );
  v.extend("mobile_jump", mobileJumpCheck, "Mobile number changed");
  v.extend("dob", dob_validation, "Date must be in format DD/MM/YYYY.");
  v.extend("dob_length", dob_length_validation, "dob must have 10 digits");
  v.extend("postcode", postcode_validation, "Please enter a valid postcode");
  v.extend("business_data", (name, value) => businessDataValidation(name, value, data), "");
  v.extend("postcode_jump", postcodeJumpCheck, "Please enter a valid postcode");
  v.extend("company_exists_check", company_exists, "Company exists");
  v.extend(
    "postcode_manual",
    postcode_manual_validation,
    "Please supply full address"
  );
  v.extend(
    "age",
    age_validation,
    "Your age should be 18 or more to apply for a loan"
  );
  v.extend(
    "director_name_manual",
    director_name_manual_validation,
    "Please enter a valid name"
  );
  v.extend("otp", () => false, "Please enter a valid name");

  return v;
}

function validateAll(data, name) {
  const v = getValidator(data);

  const msg = {
    valid: v.passes(),
    errors: v.getErrors(),
  };

  return msg;
}
function validateJump(value, name, data) {
  const { jump_allowed_if } = stepsList[name];

  data[name] = value?.target?.value ?? value;

  const vRules = {};
  vRules[name] = jump_allowed_if;

  const v = getValidator(data, vRules);

  return v.passes();
}

let totalSteps = 15;

function Steps({
  onSubmit,
  isMobile,
  startFrom,
  finishAt,
  onFinish,
  embeded,
  companyId,
  onEmbededClose,
  external,
  submitText,
  isLoading,
}) {
  const [step, setStep] = useState(startFrom || "loan_amount"); // loan_amount
  const [prevStep, setPrevStep] = useState(false);

  //step number
  const [stepNumber, setStepNumber] = useState(1);
  const [percent, setPercent] = useState(0);

  const [stepHistory, setStepHistory] = useState([]);

  const [done, setDone] = useState(false);

  const [ctaEnabled, setEnabled] = useState(false);

  const [data, setData] = useState({});

  //input value
  const [value, setValue] = useState(null);

  const [isBusinessName, setBusinessName] = useState(false);

  const [otpToken, setOtpToken] = useState(null);

  //validation
  const [valid, setValid] = useState({ valid: false, errors: [] });
  const [errors, setError] = useState([]);
  const currentStep = stepsList[step];
  const stepItem = {
    ...currentStep,
    auto_next: embeded && !external ? false : currentStep.auto_next,
  };

  if (!stepItem.Component) {
    stepItem.Component = function (props) {
      function handleChange(e) {
        props.setValue(e.target.value);
      }
      return (
        <Input
          onChange={handleChange}
          onFocus={makeHandleFocus(isMobile, 2.2)}
          inputMode="email"
          {...props}
        />
      );
    };
  }

  function handleKeyPress(e) {
    const key = e.data;

    if (stepItem.validation.keyRule !== false) {
      const valid = validateValue(key, stepItem.validation.keyRule);
      if (!valid) {
        e.preventDefault();
      }
    }
  }

  async function handleSendOtp(mobile) {
    const token = await otpSend(mobile);
    setOtpToken(token);
  }

  async function handleResendOtp(mobile) {
    const token = await otpResend(mobile, otpToken);
    setOtpToken(token);
  }

  function takeNextStep(nextStep, config = {}) {
    const defaultConfig = {
      delay: 0,
      skipped: false,
    };
    config = Object.assign(defaultConfig, config);

    if (step === "mobile_number" && value) {
      handleSendOtp(value);
    }

    //set current step as prev step
    //push current step to history
    if (stepItem.back !== false) {
      setPrevStep(step);
      setStepHistory([...stepHistory, step]);
    }
    //next
    setStep(nextStep);
  }

  useEffect(() => {
    if (stepHistory.length === 0) {
      setPrevStep(false);
    }
  }, [stepHistory]);

  function previous() {
    const len = stepHistory.length;
    if (len === 0) setPrevStep(false);

    const lastStep = stepHistory[len - 1] ?? false;
    if (lastStep) {
      const stepToTake = lastStep;

      const secondLastStep = stepHistory[len - 2] ?? false;

      if (secondLastStep) {
        setPrevStep(lastStep);
      }

      const newHistory = stepHistory.slice(0, len - 1);
      setStepHistory(newHistory);

      stepsList[stepToTake].auto_next = false;
      setStep(stepToTake);
    }
  }

  function next(allowJump = true) {
    if (stepItem.next_steps) {
      const len = stepItem.next_steps.length;
      let next,
        vRules = {},
        skipped = false;
      for (let i = 0; i < len; i++) {
        next = stepItem.next_steps[i];
        if (next.skip_if) {
          vRules = {};
          vRules[step] = next.skip_if;
          const v = getValidator(data, vRules);
          if (!v.passes()) {
            skipped = true;
            break;
          }
        }
        //with this, it will only ever check one step
        //why is it here?
        // else {
        //   break;
        // }
      }

      if (
        (next.step === finishAt ||
          (finishAt && finishAt.includes(next.step))) &&
        typeof onFinish === "function"
      ) {
        return onFinish(data);
      }

      takeNextStep(next.step, { skipped: false });
      setDone(stepItem.next === undefined);
    } else if (stepItem.next) {
      let nextStep = stepItem.next;
      let skipped = false;
      if (typeof stepItem.next === "string") {
        if (stepItem.next_step_if_valid) {
          const shouldJump = allowJump && validateJump(value, step, data);
          if (shouldJump) {
            nextStep = stepItem.next_step_if_valid;
            skipped = true;
          } else if (stepItem.next_step_if_invalid) {
            nextStep = stepItem.next_step_if_invalid;
            skipped = true;
          } else {
          }
        }
      }

      if (
        (nextStep === finishAt || (finishAt && finishAt.includes(nextStep))) &&
        typeof onFinish === "function"
      ) {
        return onFinish(data);
      }
      takeNextStep(nextStep, { skipped });
      setDone(stepItem.next === undefined);
    }

    if (stepItem.isFinalStep) {
      onSubmit(data);
    }
  }

  async function checkIfOtpValid() {
    const phoneNumber = data?.mobile_number;

    if (!value || (value && value.length < 4)) {
      setError([]);
      return setValid({
        valid: false,
        errors: [],
      });
    }

    if (value && phoneNumber && otpToken) {
      const valid = await otpVerify(phoneNumber, otpToken, value);
      if (valid) {
        setValid({
          valid: true,
          errors: [],
        });
        setError([]);
        const nextStep = stepsList.otp.next;
        if (
          (nextStep === finishAt ||
            (finishAt && finishAt.includes(nextStep))) &&
          typeof onFinish === "function"
        ) {
          return onFinish(data);
        }
        takeNextStep(nextStep);
      } else {
        setValid({
          valid: false,
          errors: [stepsList.otp.validation.messages],
        });
        return setError([stepsList.otp.validation.messages]);
      }
    }
  }

  async function checkIfValidNumber(mobile) {
    if (mobile) {
      let res = await validatePhone(mobile);
      if (res.ok) {
        setValid({
          valid: true,
          errors: [],
        });
        setError([]);
        // const nextStep = stepsList.mobile_number.next;
        // if (
        //   (nextStep === finishAt ||
        //     (finishAt && finishAt.includes(nextStep))) &&
        //   typeof onFinish === "function"
        // ) {
        //   return onFinish(data);
        // }
        // takeNextStep(nextStep);
      } else {
        setValid({
          valid: false,
          errors: ["Please provide a valid mobile number"],
        });
        return setError(["Please provide a valid mobile number"]);
      }
    }
  }

  const checkIfValidData = () => {
    setError([]);

    let fieldData = { ...data };
    fieldData[step] = value;

    const validationResult = validateAll(fieldData, step);

    const isStepValid = validationResult.errors[step] === undefined;
    setData(fieldData);

    if (step === "mobile_number" && isStepValid) {
      return checkIfValidNumber(value);
    }

    setValid({
      valid: isStepValid,
      errors: validationResult.errors[step] || [],
    });

    if (!isStepValid && value) {
      setError(validationResult.errors[step]);
    }
  };

  const checkIfValid = useCallback(async () => {
    try {
      if (step === "otp") {
        if (value.length === 4 && otpToken) {
          const valid = await otpVerify(data?.mobile_number, otpToken, value);
          if (valid) {
            setValid({ valid: true, errors: [] });
            setError([]);
            const nextStep = stepsList.otp.next;
            if (
              (nextStep === finishAt || (finishAt && finishAt.includes(nextStep))) &&
              typeof onFinish === "function"
            ) {
              return onFinish(data);
            }
            takeNextStep(nextStep);
          } else {
            const errorMessage = stepsList.otp.validation.messages;
            setValid({ valid: false, errors: [errorMessage] });
            setError([errorMessage]);
          }
        }
      } else {
        checkIfValidData();
      }
    }
    catch (error) {
      console.error("Error in checkIfValid:", error);
    }
  }, [value]);




  useEffect(() => {
    checkIfValid();
  }, [value, checkIfValid]);

  useEffect(() => {
    if (isBusinessName) {
      checkIfValidData();
    }

  }, [isBusinessName, checkIfValid]);

  useEffect(() => {
    setEnabled(valid.valid);
    if (valid.valid) {
      if (stepItem.auto_next && stepItem.id !== startFrom) {
        next();
      }
    }
  }, [valid]);

  useEffect(() => {
    setValue(data[step]);
    setStepNumber(stepsList[step].index);
  }, [step]);

  useEffect(() => {
    setPercent(Math.round((stepNumber / totalSteps) * 100));
  }, [stepNumber]);

  return (
    <div>
      <Row>
        {!embeded && (
          <Col span="24">
            <Progress
              strokeColor={COLORS.red}
              type="line"
              format={(p) => { }}
              percent={percent}
              steps={totalSteps}
              style={{
                width: "12px",
                height: "6px",
                borderRadius: "10px",
              }}
            />
          </Col>
        )}
        {!embeded && (
          <Col span="24" style={{ marginTop: "0.55vh", marginBottom: "4.6vh" }}>
            <Text
              style={{
                color: COLORS.black,
                fontWeight: 600,
                fontSize: "clamp(12px, 1.4814vh, 16px)",
              }}
              strong
            >
              Step {stepNumber} of {totalSteps}
            </Text>
          </Col>
        )}
        <Col span="24" style={{ width: "auto" }}>
          <Field
            setValue={setValue}
            setEnabled={setEnabled}
            data={data}
            setData={setData}
            valid={valid}
            setValid={setValid}
            onBeforeInput={handleKeyPress}
            errors={errors}
            advanceToNextStep={next}
            isMobile={isMobile}
            embeded={embeded}
            {...stepItem}
          >
            <stepItem.Component
              name={step}
              onBeforeInput={handleKeyPress}
              data={data}
              setData={setData}
              setValue={(e) => {
                if (e === value) {
                  setBusinessName(true)
                } else {
                  setBusinessName(false)
                }
                setValue(e);

              }}
              value={value}
              defaultValue={data[step] || ""}
              errors={errors}
              setError={setError}
              advanceToNextStep={next}
              isMobile={isMobile}
              companyId={companyId}
              onResend={handleResendOtp}
              {...stepItem}
            />
          </Field>
        </Col>
        <Col span="24">
          <Row
            justify={"space-between"}
            align={"middle"}
            style={{ marginBottom: "clamp(64px, 7.4vh, 82px)" }}
          >
            {(prevStep || embeded) && (
              <Col>
                <CTA
                  type="text"
                  style={{
                    padding: 0,
                    fontSize: isMobile ? "15px" : "clamp(17px, 1.9444vh, 21px)",
                    color: COLORS.darkGrey,
                  }}
                  ctaEnabled={prevStep !== false || embeded !== false}
                  onClick={prevStep ? previous : onEmbededClose}
                >
                  <LeftOutlined />
                  {step === startFrom ? "Cancel" : "Back"}
                </CTA>
              </Col>
            )}

            <Col>
              {!done && (
                <StepForwardButton
                  enabled={
                    ctaEnabled ||
                    (step === "otp" &&
                      value.length === 4 &&
                      errors.length === 0)
                  }
                  text={stepItem.buttonText}
                  onClick={step !== "otp" ? next : () => null}
                  hasIcon={stepItem.buttonIcon !== false && finishAt !== step}
                  large={isMobile && stepItem.buttonLarge}
                  medium={stepItem.buttonMedium}
                  isMobile={isMobile}
                  loading={isLoading}
                >
                  {finishAt && "startFrom"
                    ? submitText || "Update"
                    : stepItem.buttonText || "Next"}
                </StepForwardButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export function links() {
  const fontWeights = ["bold", "400", "500", "600"].join(",");

  return [
    {
      rel: "stylesheet",
      href: "https://fonts.googleapis.com/css?family=Montserrat:" + fontWeights,
      crossOrigin: "true",
    },
  ];
}

export default function Customer({
  embeded,
  startFrom,
  finishAt,
  onFinish,
  onEmbededClose,
  companyId,
  external,
  submitText,
}) {
  const [finished, setFinished] = useState(false);
  const [data, setData] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitting, setSubmit] = useState(false);

  let pathParams = useParams();
  const formRef = useRef();
  const navigate = useNavigate();

  const formatOtherDirectorHomeStatus = (status) => {
    if (status === undefined) return {};
    return status === "yes" ? true : false;
  };

  const onFinishMainForm = (values) => {
    setSubmit(true);

    setData({
      ...values,
      ...formatOtherDirectorHomeStatus(values.other_director_home_status)
    });
  };

  useEffect(() => {
    //modify for embed
    if (external) {
      Object.keys(URLS).forEach((endpoint) => {
        if (URLS[endpoint].indexOf(externalBaseURL) === -1) {
          URLS[endpoint] = externalBaseURL + URLS[endpoint];
        }
      });
    }

    if (pathParams.sourceId && affiliateStyleSheetMap[pathParams.sourceId]) {
      const style = document.createElement("link");
      style.href = affiliateStyleSheetMap[pathParams.sourceId];
      style.rel = "stylesheet";
      style.async = true;

      document.head.appendChild(style);
    }
  }, []);

  useEffect(() => {
    if (finished) {
      if (external) {
        window.top.location.href = "https://bs.loantube.com/dashboard/success";
      } else {
        navigate("/dashboard/success", {
          state: { ...data, isSubmitSuccess: true, id: finished },
        });
      }
    }
  }, [finished]);

  useEffect(() => {
    if (data) {
      async function create_lead() {
        // const fixedData = { ...data };
        // try {
        //   if (typeof fixedData.residential_postcode === "string") {
        //     fixedData.residential_postcode = JSON.parse(
        //       fixedData.residential_postcode
        //     );
        //   }
        // } catch (e) {
        //   //is it not json?
        // }
        let pickParams = [
          "utm_website",
          "utm_webpage",
          "utm_source_id",
          "utm_sub_source_id",
        ];

        let params = {};

        pickParams.forEach((key) => {
          let paramValue = searchParams.get(key);
          if (paramValue) {
            params[key] = paramValue;
          }
        });

        if (pathParams.sourceId) {
          params["utm_source_id"] = pathParams.sourceId;
        }

        // let params = {
        //   utm_website: searchParams.get("utm_website"),
        //   utm_webpage: searchParams.get("utm_webpage"),
        //   utm_source_id: searchParams.get("utm_source_id") ?? pathParams.sourceId,
        //   utm_sub_source_id: searchParams.get("utm_sub_source_id"),
        // };
        // Object.keys(params).forEach()
        // console.log(params);
        const res = await fetch(
          URLS.create_lead + "?" + new URLSearchParams(params),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            // credentials: 'include',
            body: JSON.stringify(data),
          }
        );

        const response = await res.json();

        setFinished(response?.id);
      }

      create_lead();
    }
  }, [data]);

  const isMobile = useMediaQuery(isMobileMediaQuery);
  useBlockZoom();

  return (
    <ConfigProvider theme={THEME}>
      <Row gutter={["8px", "8px"]} style={{ height: !embeded && "100vh" }}>
        {!embeded && <CustomerSidebar />}

        <Col
          style={{
            flex: 1,
            padding: isMobile ? "0 12px" : embeded ? "" : "0 5.1vw 0 0",
          }}
        >
          {!embeded && (
            <Row className="top-row">
              {isMobile && (
                <img
                  className="logo"
                  src="/assets/logo.svg"
                  alt="loantube logo"
                  style={{ height: "clamp(20px, 4.14vh, 35px)" }}
                />
              )}

              <PhoneNumberInfo isMobile={isMobile} />
            </Row>
          )}
          <Col
            style={{
              width: "clamp(528px, 32vw, 630px)",
              margin: "0 auto",
            }}
          >
            <Row align={"middle"} justify={"center"} className="main-form">
              <Col>
                <Form name="lead" ref={formRef}>
                  <Steps
                    startFrom={startFrom}
                    finishAt={finishAt}
                    isMobile={isMobile}
                    onSubmit={onFinishMainForm}
                    onFinish={onFinish}
                    embeded={embeded}
                    external={external}
                    onEmbededClose={onEmbededClose}
                    companyId={companyId}
                    submitText={submitText}
                    isLoading={isSubmitting}
                  />
                </Form>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </ConfigProvider>
  );
}
