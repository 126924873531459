import { Col, Button } from "antd";

import { useState, useEffect } from "react";

export default function CTA({
  children,
  type,
  ctaEnabled,
  className,
  loading,
  ...props
}) {
  const [disabled, setDisabled] = useState(false);
  const { buttonText, ...p } = props;

  useEffect(() => {
    setDisabled(!(ctaEnabled === true));
  }, [ctaEnabled]);

  return (
    <Col>
      <Button
        type={type ?? "primary"}
        disabled={disabled}
        fstyle={{ fontWeight: 500, fontSize: "90px" }}
        size="large"
        className={className}
        style={{ fontSize: "90px" }}
        loading={loading}
        {...p}
      >
        {children}
      </Button>
    </Col>
  );
}
