import FormPopup from "../../../../components/CustomerComponents/FormPopup";
import Label from "../../../../components/CustomerComponents/Label";
import StepSubTitle from "../../../../components/CustomerComponents/StepSubtitle";
import { Typography } from "antd";
import { useState, useEffect } from "react";
import Select from "../../../SinglePageForm/Components/Select";
import StepForwardButton from "../../../../components/CustomerComponents/StepForwardButton";
import { Row, Input, Spin } from "antd";
import Currency from "../../../../components/CustomerComponents/Currency";
import { List } from "antd";
import {
  validateFieldRequired,
  scrollToCenter,
} from "../../../SinglePageForm/utils";
import { LeftOutlined } from "@ant-design/icons";
import CTA from "../../../../components/CustomerComponents/Cta";
import { COLORS } from "../../../../core/constants";
import {
  submitAdditionalQuestions,
  getAdditionalInfo,
} from "../../../../core/services";
import Error from "../../../../components/CustomerComponents/Error";

const yesAndNoOptions = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

const loanTermOptions = [
  { id: "6", text: "6 Months" },
  { id: "12", text: "12 Months" },
  { id: "24", text: "2 Years" },
  { id: "36", text: "3 Years" },
  { id: "48", text: "4 Years" },
  { id: "60", text: "5 Years" },
  { id: "72", text: "6 Years" },
];

const data = [
  {
    description: (
      <div>
        for the purposes of purchasing land or property with the intention to
        rent or sell,{" "}
      </div>
    ),
  },
  {
    description: <div>changing your core business activities, </div>,
  },
  {
    description: <div>paying off your personal debts, </div>,
  },
  {
    description: <div>financing ventures outside the UK,</div>,
  },
  {
    description: (
      <div>
        for the benefit of any business other than the one mentioned in this
        appliaction.
      </div>
    ),
  },
];

const validateMoreThanZero = (value, required, message) => {
  if (typeof value !== 'number' && !required) return 

  if (value <= 0) {
    return message
  }
};

const validateNotLessThanOne = (value, required, message) => {
  const val = parseInt(value);
  if (typeof val !== 'number' && !required) return 

  if (val < 1) {
    return message
  }
};

const ShareholderDetailsForm = ({
  visible,
  onClose,
  isMobile,
  onSubmit,
  onBack,
  leadId,
  showBack,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectEnhancerEnabled, setIsSelectEnhancerEnabled] = useState(false);
  const [isSubmitting, setSubmit] = useState(false);

  const [formData, setFormData] = useState({
    director: "",
    dob: "",
    percentage: null,
    residentialAddress: null,
    residentiaHouseNumber: "",
    residentialStreet: "",
    residentialCity: "",
    residentialCounty: "",
    profit: "",
    profitAmount: null,
    lossAmount: null,
    overdraftLimit: "",
    totalOverdraftLimit: null,
    usedOverdraftLimit: null,
    existingLoans: "",
    loanAmount: null,
    monthlyRepayment: null,
    numberOfEmployees: null,
    loanTerm: null,
  });

  const getYesOrNoFromResponse = (data) => {
    if (data === undefined || data === null) return null;
    return data ? "yes" : "no";
  };

  useEffect(() => {
    async function getData() {
      const response = await getAdditionalInfo(leadId);
      setFormData({
        profit: getYesOrNoFromResponse(response.data.profit_last_year),
        profitAmount: response.data.profit,
        lossAmount: response.data.loss,
        overdraftLimit: getYesOrNoFromResponse(response.data.overdraft_limit),
        totalOverdraftLimit: response.data.total_overdraft_limit,
        usedOverdraftLimit: response.data.overdraft_limit_used,
        existingLoans: getYesOrNoFromResponse(response.data.loan_past_year),
        loanAmount: response.data.loan_past_year_amount,
        monthlyRepayment: response.data.total_monthly_repayment,
        numberOfEmployees: response.data.employee_count,
        loanTerm: String(response.data.loan_duration),
      });

      setIsLoading(false);
    }

    getData();
  }, []);

  const fields = [
    {
      name: "profit",
      label: "Profit Last Year",
      title: "Did your business make profits as per last filed accounts",
      placeholder: "Select",
      value: formData.profit,
      Component: Select,
      onSelect: (id) => setFormData({ ...formData, profit: id }),
      options: yesAndNoOptions,
      scrollIntoView: true,
      withSubtitle: true,
      errors: validateFieldRequired(formData.profit, true),
      defaultValue: formData.profit,
    },
    {
      name: "profitAmount",
      label: "Profit Amount",
      title: "What is the profit amount as per your last filed accounts?",
      placeholder: "Amount",
      data: { loanAmount: undefined },
      Component: Currency,
      setValue: (value) => setFormData({ ...formData, profitAmount: value }),
      withSubtitle: true,
      visible: formData.profit === "yes",
      errors: validateFieldRequired(formData.profitAmount, true),
      defaultValue: formData.profitAmount,
    },
    {
      name: "lossAmount",
      label: "Loss Amount",
      title: "What is the loss amount as per your last filed accounts?",
      data: { loanAmount: undefined },
      placeholder: "Amount",
      Component: Currency,
      setValue: (value) => setFormData({ ...formData, lossAmount: value }),
      withSubtitle: true,
      visible: formData.profit === "no",
      errors: validateFieldRequired(formData.lossAmount, true),
      defaultValue: formData.lossAmount,
    },
    {
      name: "overdraftLimit",
      label: "Overdraft Limit",
      title: "Do you have any existing overdraft limit?",
      value: formData.overdraftLimit,
      Component: Select,
      placeholder: "Select",
      scrollIntoView: true,
      onSelect: (id) => setFormData({ ...formData, overdraftLimit: id }),
      options: yesAndNoOptions,
      errors: validateFieldRequired(formData.overdraftLimit, true),
      defaultValue: formData.overdraftLimit,
    },
    {
      name: "totalOverdraftLimit",
      label: "Total Overdraft Limit",
      title: "What is your total overdraft limit?",
      data: { loanAmount: undefined },
      Component: Currency,
      placeholder: "Amount",
      setValue: (value) =>
        setFormData({ ...formData, totalOverdraftLimit: value }),
      visible: formData.overdraftLimit === "yes",
      errors: validateMoreThanZero(formData.totalOverdraftLimit, true, 'Total needs to be more than zero'),
      validation: validateMoreThanZero(formData.totalOverdraftLimit, false, 'Total needs to be more than zero'),
      defaultValue: formData.totalOverdraftLimit,
    },
    {
      name: "usedOverdraftLimit",
      label: "Overdraft Limit Used",
      title: "How much overdraft amount is currently used?",
      data: { loanAmount: undefined },
      Component: Currency,
      placeholder: "Amount",
      setValue: (value) =>
        setFormData({ ...formData, usedOverdraftLimit: value }),
      withSubtitle: true,
      visible: formData.overdraftLimit === "yes",
      errors: validateFieldRequired(formData.usedOverdraftLimit, true),
      defaultValue: formData.usedOverdraftLimit,
    },
    {
      name: "existingLoans",
      label: "Existing Loans",
      title: "Have you taken any loans in the last 12 months?",
      value: formData.existingLoans,
      Component: Select,
      scrollIntoView: true,
      placeholder: "Select",
      onSelect: (id) => setFormData({ ...formData, existingLoans: id }),
      options: yesAndNoOptions,
      errors: validateFieldRequired(formData.existingLoans, true),
      defaultValue: formData.existingLoans,
    },
    {
      name: "loanAmount",
      label: "Loan Amount",
      title: "How much did you borrow in last 12 months?",
      data: { loanAmount: undefined },
      Component: Currency,
      placeholder: "Amount",
      setValue: (value) => setFormData({ ...formData, loanAmount: value }),
      visible: formData.existingLoans === "yes",
      errors: validateMoreThanZero(formData.loanAmount, true, 'Total needs to be more than zero'),
      validation: validateMoreThanZero(formData.loanAmount, false, 'Total needs to be more than zero'),
      defaultValue: formData.loanAmount,
    },
    {
      name: "monthlyRepayment",
      label: "Monthly Repayment",
      title: "How much total do you pay monthly for all your loans?",
      data: { loanAmount: undefined },
      Component: Currency,
      setValue: (value) =>
        setFormData({ ...formData, monthlyRepayment: value }),
      visible: formData.existingLoans === "yes",
      placeholder: "Amount",
      errors: validateMoreThanZero(formData.monthlyRepayment, true, 'Total needs to be more than zero'),
      validation: validateMoreThanZero(formData.monthlyRepayment, false, 'Total needs to be more than zero'),
      defaultValue: formData.monthlyRepayment,
    },
    {
      name: "numberOfEmployees",
      label: "No. of Employees",
      title: "How many employees do you have?",
      data: { loanAmount: undefined },
      Component: Input,
      onChange: (e) =>
        setFormData({ ...formData, numberOfEmployees: e.target.value }),
      onKeyDown: (e) => {
        const key = e.key;
        if (/^\d+$/.test(key) || e.key.length > 1) {
          return;
        }
        e.preventDefault();
      },
      errors: validateNotLessThanOne(formData.numberOfEmployees, true, 'Employee count cannot be less than 1'),
      validation: validateNotLessThanOne(formData.numberOfEmployees, false, 'Employee count cannot be less than 1'),
      defaultValue: formData.numberOfEmployees,
    },
    {
      name: "loanTerm",
      label: "Loan-Term",
      title: "How long do you need the loan for?",
      data: { loanAmount: undefined },
      Component: Select,
      scrollIntoView: true,
      placeholder: "Select Term",
      options: loanTermOptions,
      onSelect: (id) => setFormData({ ...formData, loanTerm: id }),
      errors: validateFieldRequired(formData.loanTerm, true),
      defaultValue: formData.loanTerm,
    },
  ];

  const hasErrors = fields
    .filter((f) => f.visible !== false)
    .map((f) => f.errors)
    .some((err) => !!err);

  const onSelectEnhancer = (name, offset = 0) => {
    if (isSelectEnhancerEnabled) {
      setTimeout(() => {
        const currentIndex = fields.findIndex((f) => f.name === name);
        const nextField = fields.slice(currentIndex + 1)[offset];

        if (!nextField) {
          return false;
        }

        const nextFieldInputElement = document.querySelector(
          `input[name="${nextField.name}"]`
        );

        if (nextFieldInputElement) {
          nextFieldInputElement.focus();
          isMobile && scrollToCenter(nextFieldInputElement);
          return true;
        }
        return onSelectEnhancer(name, offset + 1);
      }, 100);
    }
  };

  const makeHandleSelect =
    (onSelect, name) =>
    (...args) => {
      if (typeof onSelect === "function") {
        typeof onSelectEnhancer === "function" && onSelectEnhancer(name);
        onSelect(...args);
      }
    };

  const handleSubmit = () => {
    setSubmit(true);
    submitAdditionalQuestions(leadId, {
      profit_last_year: formData.profit === "yes",
      profit: formData.profitAmount,
      loss: formData.lossAmount,
      overdraft_limit: formData.overdraftLimit === "yes",
      total_overdraft_limit: formData.totalOverdraftLimit,
      overdraft_limit_used: formData.usedOverdraftLimit,
      loan_past_year: formData.existingLoans === "yes",
      loan_past_year_amount: formData.loanAmount,
      total_monthly_repayment: formData.monthlyRepayment,
      employee_count: Number(formData.numberOfEmployees),
      loan_duration: Number(formData.loanTerm),
    });
    onSubmit();
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setIsSelectEnhancerEnabled(true);
      }, 200);
    } else {
      setIsSelectEnhancerEnabled(false);
      setSubmit(false);
    }
  }, [visible]);

  return (
    <FormPopup
      visible={visible}
      isMobile={isMobile}
      onClose={onClose}
      persistent={true}
      className={!isMobile && "scrollbar"}
    >
      <div
        className={[
          "single-page-form light",
          !isMobile ? "scrollbar" : "",
        ].join(" ")}
      >
        <Typography.Title
          style={{
            fontSize: isMobile ? "22px" : "clamp(24px, 2.461vh, 28px)",
            marginTop: isMobile && "4px",
            marginBottom: "30px",
            marginTop: "32px",
            paddingLeft: isMobile ? "8px" : "60px",
            paddingRight: isMobile ? "8px" : "0",
          }}
        >
          Additional Details
        </Typography.Title>
        <div
          style={{
            maxHeight: isMobile
              ? "calc(100svh - 264px)"
              : "calc(100svh - 200px)",
            minWidth: !isMobile && "560px",
            overflow: "auto",
            paddingLeft: isMobile ? "8px" : "60px",
            marginRight: isMobile ? "0px" : "30px",
            pointerEvents: isLoading && "none",
            opacity: isLoading && "0.1",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <div
              style={{
                maxWidth: "560px",
                marginRight: !isMobile && "30px",
                padding: isMobile && "0 8px",
                margin: isMobile && "0 auto",
              }}
              key={isLoading}
            >
              {fields.map(
                (
                  {
                    Component,
                    label,
                    title,
                    withSubtitle,
                    visible,
                    onSelect,
                    name,
                    validation,
                    ...props
                  },
                  index
                ) =>
                  visible !== false ? (
                    <div style={{ position: "relative" }}>
                      <Typography.Text
                        style={{
                          fontWeight: "600",
                          fontSize: isMobile ? "16px" : "18px",
                          margin: "20px 0 0px",
                          display: "block",
                        }}
                      >
                        {title}
                      </Typography.Text>
                      <div
                        style={{
                          maxHeight: "72.5px",
                          position: "relative",
                          zIndex: 1000 - index,
                          marginBottom: withSubtitle ? "40px" : "24px",
                          marginTop: "-4px",
                          maxWidth: "536.250px",
                        }}
                      >
                        <Label bg={"#fafafa"} label={label}></Label>
                        <Component
                          {...props}
                          name={name}
                          onSelect={makeHandleSelect(onSelect, name)}
                        ></Component>
                        {validation && (
                          <div style={{ position: "relative" }}>
                            <div style={{ position: "absolute", top: "2.5px" }}>
                              <Error
                                errors={validation && [validation]}
                                isMobile={isMobile}
                                decreaseTopSpacing={true}
                                iconSize={"12px"}
                                style={{ fontSize: "12px" }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {withSubtitle && (
                        <StepSubTitle
                          style={{
                            marginTop: "12px",
                            maxWidth: "536.250px",
                            height: "0px",
                            position: "absolute",
                            bottom: "0px",
                          }}
                          gap={"4px"}
                          fontSize={isMobile ? "11.3px" : "12px"}
                          iconFontSize={isMobile ? "12px" : "13px"}
                        >
                          If you don’t know the exact amount, a rough estimate
                          is fine.
                        </StepSubTitle>
                      )}
                    </div>
                  ) : (
                    <></>
                  )
              )}
            </div>
          </div>
          <div style={{ padding: isMobile && "0 8px" }}>
            <Typography.Text
              style={{
                fontWeight: "600",
                fontSize: isMobile ? "16px" : "18px",
                margin: isMobile ? "28px 0 6px" : "36px 0 6px",
                display: "block",
                width: !isMobile && "536.250px",
              }}
            >
              By clicking submit you confirm that you will not use this loan:
            </Typography.Text>
            <div>
              <List
                itemLayout="horizontal"
                dataSource={data}
                bordered={false}
                split={false}
                renderItem={(item, index) => (
                  <List.Item
                    style={{
                      padding: "0px 8px 0",
                      marginBottom: index === 0 ? "4px" : "0px",
                      width: !isMobile && "536.250px",
                    }}
                  >
                    <List.Item.Meta
                      avatar={
                        <span
                          style={{
                            color: "#7a7a7a",
                            fontWeight: 500,
                            position: "relative",
                            top: isMobile ? "-4px" : "-3px",
                            fontSize: isMobile ? "12px" : "14px",
                          }}
                        >{`${index + 1}.`}</span>
                      }
                      description={
                        <div
                          style={{
                            fontSize: isMobile ? "14px" : "16px",
                            lineHeight: "18px",
                          }}
                        >
                          {item.description}
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              top: "140px",
            }}
          >
            <Spin />
          </div>
        )}
        <Row
          style={{
            marginTop: "24px",
            marginBottom: "24px",
            paddingLeft: isMobile ? "8px" : "60px",
            paddingRight: isMobile ? "8px" : "53px",
            pointerEvents: isLoading && "none",
            opacity: isLoading && "0.1",
          }}
          justify={showBack ? "space-between" : "end"}
        >
          {showBack && (
            <CTA
              type="text"
              style={{
                padding: 0,
                fontSize: isMobile ? "15px" : "clamp(17px, 1.9444vh, 21px)",
                color: COLORS.darkGrey,
              }}
              ctaEnabled={true}
              onClick={onBack}
            >
              <LeftOutlined />
              Back
            </CTA>
          )}
          <StepForwardButton
            isMobile={isMobile}
            onClick={handleSubmit}
            enabled={!hasErrors}
            loading={isSubmitting}
          >
            Submit
          </StepForwardButton>
        </Row>
      </div>
    </FormPopup>
  );
};

export default ShareholderDetailsForm;
