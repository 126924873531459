import { otpSend, otpVerify } from "../Customer/services";
import { URLS } from "../../core/constants";
import { headers } from "../../core/constants";
import { Content } from "antd/es/layout/layout";

const MOCK_RESPONSES = false;

export const whoami = async () => {
  const response = await fetch(
    `/api/auth/whoami`,
    {
      headers: {
        ...headers,
        "Content-Type": "application/json",
      },
      method: "POST",
    }
  );
  const data = await response.json();

  if (MOCK_RESPONSES) return { ...data, role: 'agent' };

  return data;
};

const formatCompany = (company) => {
  if (company.name === "My business is not listed here") {
    return {};
  }
  return company
};

const formatDirectorName = (directorName) => {
  if (directorName === 'not_listed_here') {
    return {}
  }
  return {
    director_name: directorName
  }
}

const formatDirectorHomeStatus = (directorHomeStatus) => {
  if (directorHomeStatus === undefined || directorHomeStatus === null) return {};

  return {
    other_director_home_status: directorHomeStatus === 'yes' ? true : false,
  };
}

const formatResidentialPostcode = (formData) => {
  if (formData.residentialAddress?.address) {
    return {
      residential_postcode: JSON.stringify(formData.residentialAddress)
    }
  } else {
    return {
      residential_postcode: JSON.stringify(formData.residentialAddress),
      residential_postcode_manual: {
        address: false,
        city: formData.residentialCity,
        county: formData.residentialCounty,
        house_number: formData.residentiaHouseNumber,
        postcode: formData.residential_postode_manual,
        street: formData.residentialStreet
      }
    }
  }
}

const formatPostcode = (formData) => {
  if (formData.businessAddress?.address) {
    return {
      postcode: JSON.stringify(formData.businessAddress)
    }
  } else {
    return {
      postcode: JSON.stringify(formData.businessAddress),
      postcode_manual: {
        address: false,
        city: formData.city,
        county: formData.county,
        house_number: formData.houseNumber,
        postcode: formData.postode_manual ?? formData.manual_postcode,
        street: formData.street
      }
    }
  }
}

const getSubmitUrl = (patch, sendSms, leadId) => {
  const searchParams = new URLSearchParams(window.location.search);
  let pickParams = [
    "utm_website",
    "utm_webpage",
    "utm_source_id",
    "utm_sub_source_id",
  ];

  let params = {
    sendSMS : sendSms.toString()
  };

  pickParams.forEach((key) => {
    let paramValue = searchParams.get(key);
    if (paramValue) {
      params[key] = paramValue;
    }
  });

  let url = patch ? `/api/leads/${leadId}` : `${URLS.create_lead}`;
  return url + "?" + new URLSearchParams(params).toString();
};

const getSubmitData = (data, patch) => {
  return patch ? { application: data } : data;
}

const getSubmitHeader = (patch) => patch ? headers : {};

export const handleSubmitForm = async (formData, otp, token, consent, sendSms=false, patch=false, leadId, source) => {
  let showOtp = (consent && !patch) || source === 'api';
  if (showOtp) {
    const v = await otpVerify(formData.mobileNumber, token, otp);
    if (!v) return;
  }

  Object.keys(formData).forEach((key) => {
    if (formData[key] === null || formData[key] === undefined) {
      delete formData[key]; // Remove null or undefined properties
    }
  });

  const data =  {
    loan_amount: formData.loanAmount,
    purpose: formData.purpose,
    company: formatCompany(formData.company),
    ...formatDirectorName(formData.director),
    director_name_manual: {
      first_name: formData.firstName,
      last_name: formData.lastName
    },
    business_name: formData?.company?.name,
    business_name_manual: formData.businessName,
    business_type: formData.businessType,
    business_age: formData.businessAge,
    mobile_number: formData.mobileNumber,
    email: formData.email,
    residential_status: formData.residentialStatus,
    property_value: formData.propertyValue,
    revenue: formData.annualSales,
    accept_card_payments: formData.cardPayments,
    card_amount: formData.cardAmountOptions,
    invoices: formData.invoices,
    dob: formData.dob,
    ...formatDirectorHomeStatus(formData.directorHomeStatus),
    ...formatResidentialPostcode(formData),
    ...formatPostcode(formData),
    otp: formData.otp,
    job_position: formData.jobPosition,
    origin_url: window.location.href,
    consent_credit_search: consent,
    consent_contact: consent
  };

  const res = await fetch(
    getSubmitUrl(patch, sendSms, leadId),
    {
      method: patch ? "PATCH" : "POST",
      headers: {
        "Content-Type": "application/json",
        ...getSubmitHeader(patch)
      },
      credentials: patch ? 'include' : undefined,
      body: JSON.stringify(getSubmitData(data, patch)),
    }
  );

  const response = await res.json();
  
  if (response.ok && response.id);

  return [response.id, data];
};

export const getLeadById = async (leadId) => {
  const res = await fetch(
    ` /api/leads/${leadId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...headers
      },
    }
  );
  const response = await res.json();

  return response?.data
}