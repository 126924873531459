import { getName, makeHandleFocus } from "../../core/utils";
import { Input } from "antd";

export default function MobileNumber({
  data,
  defaultValue,
  isMobile,
  ...props
}) {
  let { name, label } = props;
  name = getName({ label, name });

  async function handleChange(e) {
    const val = e.target.value;
    if (val.length < 3) {
      e.preventDefault();
      return;
    }

    
    data["otp"] = false;
    props.setValue(val);
  }

  return (
    <Input
      defaultValue={defaultValue}
      maxLength="11"
      name={name}
      onChange={handleChange}
      onKeyPress={props.onKeyPress}
      onFocus={makeHandleFocus(isMobile)}
      inputMode="numeric"
      type="tel"
    />
  );
}
