import { Typography } from "antd";
import SelectInput from "./SelectInput";
import { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { COLORS } from "../../../core/constants";
import { simpleNameMatch } from "../../../core/utils";

const Select = ({ options, placeholder, onSelect, selectedId, name, defaultValue, scrollIntoView  }) => {
  const [value, setValue] = useState("");
  const [hideOptions, setHideOptions] = useState(true);

  const handleSelect = (id) => {
    setHideOptions(true);
    onSelect(id);
    setValue(options.find((option) => option.id === id).text);
  };

  const handleBlur = () => setHideOptions(true);

  const handleFocus = () => {
    setTimeout(() => {
      setHideOptions(false);
    });
  };

  const valueMatchesSelected = () => {
    const matched = options.find((opt) => opt.id === selectedId);
    if(!matched && name === 'director'){
      //fuzzy match;
      matched = options.find((option) => simpleNameMatch(option.text,defaultValue));
    }
    if(!matched) return true;
    
    return (
      value.toUpperCase() ===
      matched.text.toUpperCase()
    );
  };

  useEffect(() => {
    let defaultOption = options.find((option) => option.id === defaultValue);
    if(name === 'director'){

      let foundDirector = defaultOption && defaultOption.id !== 'not_listed_here';

      if(!foundDirector){
        defaultOption = options.find((option) => {
          return simpleNameMatch(option.text,defaultValue)
        });
      }
    }
    if (defaultOption) {
      onSelect(defaultOption.id);
      setValue(defaultOption.text);
    }
  }, []);

  return (
    <>
      <SelectInput
        value={value}
        loading={false}
        onSelect={handleSelect}
        hideOptions={hideOptions}
        placeholder={placeholder}
        name={name}
        defaultValue={defaultValue}
        hasIcon={false}
        selectedOptionId={selectedId}
        scrollIntoView={scrollIntoView}
        suffix={
          <DownOutlined
            style={{
              color: "#212121",
              fontSize: "18px",
              position: "relative",
              top: "1.5px",
            }}
          />
        }
        isMobile={false}
        hasBoxShadow={true}
        options={
          value === "" || valueMatchesSelected()
            ? options
            : options.filter((o) =>
                o.text.toUpperCase().startsWith(value.toUpperCase())
              )
        }
        infiniteHeight={true}
        onFocus={handleFocus}
        onBlur={handleBlur}
        darkText={true}
        inputStyle={{ caretColor: "transparent" }}
        className={"select-cursor-pointer"}
        readOnlyInput={true}
      />
    </>
  );
};

export default Select;
