import { Children, useState } from "react";
import { Typography, Col, Row } from "antd";
import StepSubTitle from "./StepSubtitle";
import CTA from "./Cta";
import { LeftOutlined } from "@ant-design/icons";
import StepForwardButton from "./StepForwardButton";

import { COLORS } from "../../core/constants";

const FormPopupLayout = ({
  isMobile,
  title,
  subtitles,
  children,
  onCancel,
  onSubmit,
  submitEnabled,
  submitText,
  hasIcon,
  secondarySubmit,
  secondarSubmitEnabled,
  onSecondarySubmit,
  hideCancel,
  submitStyle,
  transparent,
  actionsJustify
}) => {
  return (
    <div
      style={{
        background: transparent ? 'transparente' : !isMobile && COLORS.white,
        width: !isMobile && "clamp(500px, 23vw, 610px)",
        maxWidth: isMobile && "clamp(528px, 32vw, 630px)",
        paddingTop: "30px",
        margin: "0 auto",
        paddingLeft: isMobile && "16px",
        paddingRight: isMobile && "16px",
      }}
    >
      <Typography.Title
        style={{
          margin: !isMobile ? "0 0 3.6vh" : "0 0 2.96vh",
          fontSize: isMobile ? "18px" : "clamp(24px, 2.461vh, 28px)",
          marginTop: isMobile && '4px'
        }}
      >
        {title}
      </Typography.Title>
      {subtitles &&
        Array.isArray(subtitles) &&
        subtitles.map((subtitle, index) => (
          <StepSubTitle
            fontSize={"14px"}
            style={{ marginTop: index > 0 && "6px" }}
            textStyle={{ fontWeight: 400 }}
          >
            {subtitle}
          </StepSubTitle>
        ))}
      <div style={{ marginBottom: "24px" }}></div>

      {children}

      <Row
        justify={actionsJustify || 'space-between'}
        align={"middle"}
        style={{
          marginBottom: !hideCancel ? "40px" : "clamp(64px, 7.4vh, 82px)",
          marginTop: "clamp(30px, 2.0vh, 72px)",
        }}
      >
        <Col>
          {secondarySubmit ? (
            <StepForwardButton
              enabled={secondarSubmitEnabled}
              text={secondarySubmit}
              onClick={onSecondarySubmit}
              hasIcon={hasIcon}
              isMobile={isMobile}
            >
              {secondarySubmit}
            </StepForwardButton>
          ) : (!hideCancel &&
            <CTA
              type="text"
              style={{
                padding: 0,
                fontSize: isMobile ? "15px" : "clamp(17px, 1.9444vh, 21px)",
                color: COLORS.darkGrey,
              }}
              ctaEnabled={true}
              onClick={onCancel}
            >
              <LeftOutlined />
              Cancel
            </CTA>
          )}
        </Col>

        <Col>
          <StepForwardButton
            enabled={submitEnabled}
            text={"Send email"}
            onClick={onSubmit}
            hasIcon={hasIcon}
            isMobile={isMobile}
            style={submitStyle}
          >
            {submitText}
          </StepForwardButton>
        </Col>
      </Row>
    </div>
  );
};

export default FormPopupLayout;
