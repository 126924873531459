import { postcodeValidator } from "postcode-validator";
import { defaultDateFormat } from "../../core/constants";
import dayjs from "dayjs";
import Validator from "Validator";
import lodash from "lodash";
const { snakeCase } = lodash;
export const purposeOptions = [
  { id: "business_growth", text: "Business Growth" },
  { id: "additional_cashflow", text: "Additional Cashflow" },
  { id: "stock_inventory_purchase", text: "Stock & Inventory purchase" },
  { id: "plant_machinery_purchase", text: "Plant & Machinery Purchase" },
  { id: "vehicle_purchase", text: "Vehicle purchase" },
  { id: "existing_loan_refinance", text: "Existing Loan Refinance" },
  { id: "other_purposes", text: "Other Purposes" },
];

export const yesAndNoOptions = [
  { id: "yes", text: "Yes" },
  { id: "no", text: "No" },
];

export const cardAmountOptions = [
  { id: "up_to_25", text: "Up to 25% of sales" },
  { id: "25_50", text: "25% - 50% of sales" },
  { id: "50_75", text: "50% - 75% of sales" },
  { id: "more_than_75", text: "More than 75% of sales" },
];

export const residentialStatusOptions = [
  { id: "yes_with_a_mortgage", text: "Yes, with a mortgage" },
  { id: "yes_without_a_mortgage", text: "Yes, without a mortgage" },
  { id: "no", text: "No" },
];

export const getCardReceipts = (lead) => {
  const accept = lead?.accept_card_payments;
  const revenue = lead?.revenue;
  const cardAmount = lead?.card_amount;
  if (!accept || cardAmount === "0" || !cardAmount || !revenue) return "0";

  const amount = parseFloat(cardAmount);
  const r = parseFloat(revenue);

  if (amount <= r * 0.25) return "up_to_25";
  else if (amount <= r * 0.5) return "25_50";
  else if (amount <= r * 0.75) return "50_75";
  else return "more_than_75";
};

export const getResidentialStatus = (status) => {
  if (status === "Non Home Owner") {
    return "no";
  } else if (status === "Home Owner (Mortaged)") {
    return "yes_with_a_mortgage";
  } else if (status === "Home Owner (Mortage Free)") {
    return "yes_without_a_mortgage";
  } else if (status === "Home Owner (Mortgaged)") {
    return "yes_with_a_mortgage";
  } else if (status === "Home Owner (Mortgage Free)") {
    return "yes_without_a_mortgage";
  }
  return "";
};

export const getDirectorHomeStatus = (status) => {
  if (status === undefined || status === null) return undefined;
  return status ? "yes" : "no";
};

// export const businessTypeOptions = [
//   { id: "sole_trader", text: "Sole Trader" },
//   { id: "partnership", text: "Partnership" },
// ];

export const businessTypeOptions = [
  "Sole Trader",
  "Limited Company",
  "Partnership",
  "Limited Liability Partnership",
  // 'Charity'
].map((text) => ({ id: snakeCase(text), text }));

export const businessAgeOptions = [
  { id: "less_1_year", text: "Less than 1 year" },
  { id: "1_2_years", text: "1 - 2 years" },
  { id: "2_3_years", text: "2 - 3 years" },
  { id: "more_than_3", text: "More than 3 years" },
];

export const getBusinessAge = (bAge, lead) => {
  if (businessAgeOptions.find((b) => b.id === bAge)) {
    return bAge;
  }
  let id = businessAgeOptions.find((o) => o.text === bAge)?.id;
  if (!id && lead?.business_age_numeric_approx) {
    if (lead.business_age_numeric_approx < 1) id = "less_1_year";
    else if (
      lead.business_age_numeric_approx >= 1 &&
      lead.business_age_numeric_approx < 2
    )
      id = "1_2_years";
    else if (
      lead.business_age_numeric_approx >= 2 &&
      lead.business_age_numeric_approx < 3
    )
      id = "2_3_years";
    else if (lead.business_age_numeric_approx >= 3) id = "more_than_3";
  }
  return id;
};

export const jobPositionOptions = [
  { id: "employee", text: "Employee" },
  { id: "accountant", text: "Accountant" },
  { id: "owner", text: "Owner" },
  { id: "partner", text: "Partner" },
  { id: "director", text: "Director" },
  { id: "other", text: "Other" },
];

export const loanAmountValidation = (value, required) => {
  if (!value && required) return "Loan amount between £5,000 to £5,000,000";
  if (!value) return;
  if (!value || value < 5000 || value > 5000000)
    return "Loan amount between £5,000 to £5,000,000";
};

export const businessNameValidation = (value, required) => {
  if (!value && required) return "Type a valid business name";
  if (!value) return;
  if (!/[a-zA-Z0-9. ,&\-]/.test(value)) {
    return "Type a valid business name";
  }
};

export const validateBusinessData = (company, businessData, required) => {
  if (
    !required ||
    (!company && businessData === "My business is not listed here") ||
    businessData === "My business is not listed here"
  )
    return;
  if (company?.name === businessData) return;
  return "Please select a valid option";
};

export const validatePostcode = (value, required) => {
  if (!value && required) return "Please enter a valid postcode";

  if (!value) return;

  const valid = postcodeValidator(value, "GB");
  if (!valid) {
    return "Please enter a valid postcode";
  }
};

export const dobValidation = (date, required, message="Please provide a valid date of birth") => {
  if (!date && required) return message;

  if (!date) return;

  const complete = String(date).length === 10;
  if (!complete) return message;

  const isValid = dayjs(date, defaultDateFormat, true).isValid();

  if (!isValid) {
    return message;
  }

  return ageValidation(date);
};

export const percentageValidation = (percentage, required) => {
  const notPercentage = !percentage && percentage !== 0

  if (notPercentage && required) return "Please provide a valid percentage";

  if (notPercentage) return;

  if (percentage > 100 || percentage <= 0) {
    return "Please recheck the shareholding percentage";
  }
};

export const ageValidation = (date, required) => {
  if (!date && required) return "This field is required";

  const year = dayjs(date, defaultDateFormat).get("y");
  const age = new Date().getFullYear() - year;

  if (age > 18 && age < 100) {
    return;
  }
  return "Age should be between 18 and 100";
};

export const validatePhoneNumber = (number, required) => {
  if (!number && required) return "Please provide a valid mobile number";

  if (!number) return;

  if (number.length > 2) {
    if (!number.startsWith("07")) {
      return "Mobile number should start with 07";
    } else if (number.length < 11 || /[^0-9]/.test(number)) {
      return "Please provide a valid mobile number";
    }
  } else if (required) {
    return "Please provide a valid mobile number";
  }
};

export const validateEmail = (email, required) => {
  if (!email && required) return "Type a valid email address";

  if (!email) return;
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    return "Type a valid email address";
  }
};

export const directorNamesValidation = (name, required) => {
  if (!name && required) return "Please enter a valid name.";

  if (!name) return false;

  const rules = {
    name: ["required", "regex:/[a-z - ',.]/i"],
  };

  const v = Validator.make({ name }, rules);
  if (!v.passes()) {
    return "Please enter a valid name.";
  }
};

export const validateFieldRequired = (value, required) => {
  if (!value && required) return "This field is required";
};

function validateValue(value, rule) {
  const name = "random";
  const data = {};
  data[name] = value;

  const vRules = {};
  vRules[name] = rule;
  const v = Validator.make(data, vRules);
  return v.passes() ?? false;
}

export const preventKeyPress = (e) => {
  const key = e.data;

  const valid = validateValue(key, [`regex:/[-',.A-Za-z ]`]);
  if (!valid) {
    e.preventDefault();
  }
};

export const scrollToCenter = (element) => {
  // Get the coordinates of the element
  var elementRect = element.getBoundingClientRect();
  var elementX = elementRect.left + window.scrollX + elementRect.width / 2;
  var elementY = elementRect.top + window.scrollY + elementRect.height / 2;

  // Calculate the scroll position to center the element
  var scrollX = elementX - window.innerWidth / 2;
  var scrollY = elementY - window.innerHeight / 2;

  // Scroll to the center
  window.scrollTo({
    top: scrollY,
    left: scrollX,
    behavior: "smooth", // You can change this to 'auto' for instant scrolling
  });
};
