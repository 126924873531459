import { Typography, Col, Row, Input, ConfigProvider, Button } from "antd";
import { useEffect, useRef, us } from "react";
import LoanAmount from "../../components/CustomerComponents/LoanAmount";
import Label from "../../components/CustomerComponents/Label";
import Currency from "../../components/CustomerComponents/Currency";
import useMediaQuery from "../../core/hooks/useMediaQuery";
import { isMobileMediaQuery, THEME, COLORS } from "../../core/constants";
import Select from "./Components/Select";
import { useState } from "react";
import styles from "./style.module.css";
import DateOfBirth from "../../components/CustomerComponents/DateOfBirth";
import SinglePageFormBusinessData from "./Components/SinglePageFormBusinessData";
import FieldListRenderer from "./Components/FieldListRenderer";
import SinglePageFormAddress from "./Components/SinglePageFormAddress";
import StepForwardButton from "../../components/CustomerComponents/StepForwardButton";
import { getCompanyData } from "../../core/services";
import Footer from "./Components/Footer";
import {
  purposeOptions,
  yesAndNoOptions,
  cardAmountOptions,
  residentialStatusOptions,
  businessTypeOptions,
  businessAgeOptions,
  jobPositionOptions,
  loanAmountValidation,
  businessNameValidation,
  validatePostcode,
  dobValidation,
  validatePhoneNumber,
  validateEmail,
  directorNamesValidation,
  preventKeyPress,
  scrollToCenter,
  validateFieldRequired,
  getResidentialStatus,
  getDirectorHomeStatus,
  getCardReceipts,
  getBusinessAge,
  validateBusinessData,
} from "./utils";
import PhoneNumberInfo from "../../components/CustomerComponents/PhoneNumberInfo";
import OTPStep from "../../components/OTPStep";
import AcceptTerms from "../../components/CustomerComponents/AcceptTerms";
import FormPopup from "../../components/CustomerComponents/FormPopup";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { handleOtpAutoFocus } from "../../core/utils";
import { handleSubmitForm, getLeadById } from "./services";
import { otpSend } from "../Customer/services";

const SUBMIT_TYPES = {
  SAVE: "SAVE",
  SAVE_SMS: "SAVE_SMS",
  NORMAL: "NORMAL",
};

const SinglePageFormWrapper = ({ variation }) => {
  const [key, setKey] = useState(0);

  const handleRefresh = () => {
    setKey(key + 1);
  };

  return (
    <SinglePageForm
      key={key}
      variation={variation}
      handleRefresh={handleRefresh}
    />
  );
};

const SinglePageForm = ({ variation, role, handleRefresh }) => {
  const isMobile = useMediaQuery(isMobileMediaQuery);
  const [directorOptions, setDirectorOptions] = useState([]);
  const [validateRequired, setValidateRequired] = useState(false);
  const [done, setDone] = useState(false);
  const [currentValidation, setCurrentValidation] = useState(0);
  const [otp, setOtp] = useState("");
  const businessDataCounterRef = useRef(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [agentLeadId, setAgentLeadId] = useState(null);
  const submitType = useRef(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [baseLead, setBaseLead] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const pageLoadedTimestamp = useRef(null);
  const focusOnDirectorWhenOptionsChange = useRef(false);
  const blockAutoFocusExcept = useRef(null);
  const [skipBusAddress, setSkipBusAddress] = useState(false);

  useEffect(() => {
    const sd = searchParams.get("utm_source_id");
    if (sd === 'Seopa') {
      navigate("/?" + searchParams.toString());
    }
  }, []);
  const [formData, setFormData] = useState({
    loanAmount: null,
    purpose: null,
    annualSales: null,
    cardPayments: null,
    cardAmountOptions: null,
    invoices: null,
    director: null,
    dob: null,
    residentialStatus: null,
    propertyValue: null,
    email: searchParams.get("email") || null,
    mobileNumber: searchParams.get("phone") || null,
    businessData: null,
    businessName: null,
    businessType: null,
    businessAge: null,
  });

  const onSelectEnhancer = (name, offset = 0) => {
    setTimeout(() => {
      const currentTime = new Date().getTime();
      if (currentTime - pageLoadedTimestamp.current < 5000) {
        return;
      }

      if (
        blockAutoFocusExcept.current &&
        blockAutoFocusExcept.current !== name
      ) {
        return;
      }
      const fields = [
        ...baseFields,
        ...businessDetailsFields,
        ...yourDetailsFields,
      ];

      const currentIndex = fields.findIndex((f) => f.name === name);
      const nextField = fields.slice(currentIndex + 1)[offset];

      if (!nextField) {
        return false;
      }

      const nextFieldInputElement = document.querySelector(
        `input[name="${nextField.name}"]`
      );

      const nextFieldValue = nextFieldInputElement?.value;

      if (searchParams.get("pre") === "true" && nextFieldValue) {
        return onSelectEnhancer(name, offset + 1);
      }

      if (
        name === "businessData" &&
        nextField.name === "director" &&
        nextFieldInputElement
      ) {
        focusOnDirectorWhenOptionsChange.current = true;
        return true;
      }

      if (nextFieldInputElement) {
        nextFieldInputElement.focus();
        isMobile && scrollToCenter(nextFieldInputElement);
        return true;
      }
      return onSelectEnhancer(name, offset + 1);
    }, 100);
  };

  useEffect(() => {
    if (directorOptions.length && focusOnDirectorWhenOptionsChange.current) {
      focusOnDirectorWhenOptionsChange.current = false;
      const fieldInputElement = document.querySelector(
        `input[name="director"]`
      );
      fieldInputElement.focus();
      isMobile && scrollToCenter(fieldInputElement);
    }
  }, [directorOptions]);

  const handleSubmit = () => {
    submitType.current = SUBMIT_TYPES.NORMAL;
    setValidateRequired(true);
    setCurrentValidation(currentValidation + 1);
    handleOtpAutoFocus();
  };

  const handleSubmitSave = (_submitType) => {
    submitType.current = _submitType;
    setValidateRequired(true);
    setCurrentValidation(currentValidation + 1);
  };

  const handleModals = async () => {
    if (
      submitType.current === SUBMIT_TYPES.SAVE ||
      submitType.current === SUBMIT_TYPES.SAVE_SMS
    ) {
      const [leadId] = await handleFinishAgentForm();
      setAgentLeadId(leadId);
    } else if (submitType.current === SUBMIT_TYPES.NORMAL) {
      if (searchParams.get("pre") === "true" && location.state?.verified === true) {
        handleFinishForm();
      } else {
        const _token = await otpSend(formData.mobileNumber);
        setToken(_token);
        setDone(true);
      }
    }
  };

  const handleErrors = () => {
    const fields = [
      ...baseFields,
      ...businessDetailsFields,
      ...yourDetailsFields,
    ];
    const errorFields = fields.filter((field) => field.errors);
    if (
      !errorFields.length ||
      errorFields.every(
        (f) => document.querySelector(`input[name="${f?.name}"]`) === null
      )
    ) {
      return currentValidation > 0 && handleModals();
    }

    errorFields.every((field) => {
      const input = document.querySelector(`input[name="${field?.name}"]`);

      if (input) {
        scrollToCenter(input);
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (!pageLoadedTimestamp.current) {
      pageLoadedTimestamp.current = new Date().getTime();
    }
    // console.log("pre", searchParams.get("pre") === "true", location?.state?.leadId);
    const leadId = location?.state?.leadId || searchParams.get("leadId");
    if (searchParams.get("pre") === "true" && leadId) {
      preFillLead(leadId);
      return;
    }
    let interval = setTimeout(() => {
      const loanAmountInput = document.querySelector(
        `input[name="${baseFields[0].name}"]`
      );
      // if(!loanAmountInput) {{
      //   console.log("input not found");
      //   return;
      // }};
      loanAmountInput.focus();
    }, 60);

    setIsLoading(false);
    return () => clearTimeout(interval);
  }, []);

  const preFillLead = async (leadId) => {
    const lead = await getLeadById(leadId);
    setBaseLead({
      ...lead,
      application: {
        ...lead.application,
        director_name: lead?.application?.director_name_manual?.first_name
          ? "not_listed_here"
          : lead.application.director_name,
      },
    });
    if (lead.application.consent_credit_search) {
      window.open(`/dashboard`, "_self");
    }
    setSkipBusAddress(!lead?.application?.postcode);
    setFormData({
      ...formData,
      dob: lead.application.dob,
      email: lead.application?.email,
      propertyValue: lead.application?.property_value,
      mobileNumber: lead.application?.mobile_number,
      cardPayments:
        lead.application.accept_card_payments === true ? "yes" : "no",
      invoices: lead.application.invoices === true ? "yes" : "no",
      purpose: lead.application.purpose,
      annualSales: lead.application.revenue,
      loanAmount: lead.application.loan_amount,
      businessAge: getBusinessAge(lead.application.business_age, lead),
      businessName: lead.application.business_name,
      businessType: lead.application.business_type,
      jobPosition: lead.application.job_position,
      businessData: lead.application.company.id
        ? lead.application.company.name
        : "My business is not listed here",
      company: lead.application.company,
      director: lead?.application?.director_name_manual?.first_name
        ? "not_listed_here"
        : lead.application.director_name,
      residentialStatus: getResidentialStatus(
        lead.application.residential_status
      ),
      directorHomeStatus: getDirectorHomeStatus(
        lead.application.other_director_home_status
      ),
      cardAmountOptions: getCardReceipts(lead.application),
      residentialAddress: lead?.application?.residential_postcode
        ? lead?.application?.residential_postcode
        : { address: false },
      residential_postode_manual:
        lead?.application?.residential_postcode?.postcode ||
        lead?.application?.residential_postcode_manual?.postcode,
      businessAddress:
        lead?.application?.postcode || lead.application.company.id
          ? lead?.application?.postcode
          : { address: false },
      firstName: lead?.application?.director_name_manual?.first_name,
      lastName: lead?.application?.director_name_manual?.last_name,
      city: lead?.application?.business_address_manual?.city,
      county: lead?.application?.business_address_manual?.county,
      houseNumber: lead?.application?.business_address_manual?.house_number,
      street: lead?.application?.business_address_manual?.street,

      residentialCity: lead?.application?.residential_postcode_manual?.city,
      residentialCounty: lead?.application?.residential_postcode_manual?.county,
      residentiaHouseNumber:
        lead?.application?.residential_postcode_manual?.house_number,
      residentialStreet: lead?.application?.residential_postcode_manual?.street,
    });
  };

  const formatCardPaymentsDefaultValue = () => {
    if (!baseLead) return;
    return baseLead.application.accept_card_payments === true ? "yes" : "no";
  };

  const formatInvoicesDefaultValue = () => {
    if (!baseLead) return;
    return baseLead.application.invoices === true ? "yes" : "no";
  };

  const baseFields = [
    {
      name: "loanAmount",
      label: "Loan amount",
      data: { loanAmount: undefined },
      Component: LoanAmount,
      setValue: (value) => setFormData({ ...formData, loanAmount: value }),
      isEmpty: !formData.loanAmount,
      defaultValue:
        searchParams.get("amount") || baseLead?.application?.loan_amount,
      errors: loanAmountValidation(
        formData.loanAmount === null
          ? searchParams.get("amount")
          : formData.loanAmount,
        validateRequired
      ),
    },
    {
      name: "purpose",
      label: "Purpose",
      data: { loanAmount: undefined },
      Component: Select,
      options: purposeOptions,
      placeholder: "Select purpose",
      selectedId: formData.purpose,
      onSelect: (id) => setFormData({ ...formData, purpose: id }),
      errors: validateFieldRequired(formData.purpose, validateRequired),
      isEmpty: !formData.purpose,
      defaultValue:
        searchParams.get("purpose") || baseLead?.application?.purpose,
    },
  ];

  const businessDetailsFields = [
    {
      name: "businessData",
      label: "Business name",
      data: formData,
      Component: SinglePageFormBusinessData,
      placeholder: "Type your business name",
      setData: (newData) => {
        setFormData((data) => ({
          ...data,
          company: newData.company,
          director: null,
          changedCompany: true,
        }));
        businessDataCounterRef.current = businessDataCounterRef.current + 1;
        if (newData.company && businessDataCounterRef.current > 2) {
          onSelectEnhancer("businessData");
        }
      },
      setValue: (val) => {
        blockAutoFocusExcept.current = "businessData";
        setTimeout(() => {
          blockAutoFocusExcept.current = null;
        }, 250);
        setFormData((data) => ({ ...data, businessData: val }));
      },
      value: formData.businessData,
      single: "My business is not listed here" !== formData.businessData,
      errors: validateBusinessData(formData.company, formData.businessData, validateRequired),
      isEmpty: !formData.businessData,
    },
    {
      name: "businessName",
      label: "Type your business name",
      data: formData,
      Component: Input,
      placeholder: "Type your business name",
      onChange: (e) =>
        setFormData({ ...formData, businessName: e.target.value }),
      visible: "My business is not listed here" === formData.businessData,
      errors: businessNameValidation(formData.businessName, validateRequired),
      isEmpty: !formData.businessName,
      defaultValue: baseLead?.application?.business_name,
    },
    {
      name: "businessType",
      label: "Business type",
      data: formData,
      Component: Select,
      placeholder: "Select business type",
      options: businessTypeOptions,
      visible: "My business is not listed here" === formData.businessData,
      selectedId: formData.businessType,
      onSelect: (id) => setFormData({ ...formData, businessType: id }),
      errors: validateFieldRequired(formData.businessType, validateRequired),
      isEmpty: !formData.businessType,
      defaultValue: baseLead?.application?.business_type,
    },
    {
      name: "businessAge",
      label: "Trading since",
      data: formData,
      Component: Select,
      placeholder: "Select",
      setValue: (val) => setFormData({ ...formData, businessName: val }),
      visible: "My business is not listed here" === formData.businessData,
      options: businessAgeOptions,
      selectedId: formData.businessAge,
      onSelect: (id) => setFormData({ ...formData, businessAge: id }),
      errors: validateFieldRequired(formData.businessAge, validateRequired),
      isEmpty: !formData.businessAge,
      defaultValue: getBusinessAge(baseLead?.application?.business_age, baseLead),
    },
    {
      name: "annualSales",
      label: "Annual sales/turnover",
      placeholder: "Annual turnover",
      data: { loanAmount: undefined },
      Component: Currency,
      value: formData.annualSales,
      setValue: (val) => setFormData({ ...formData, annualSales: val }),
      errors: validateFieldRequired(formData.annualSales, validateRequired),
      isEmpty: !formData.annualSales,
      defaultValue: baseLead?.application?.revenue,
    },
    {
      name: "cardPayments",
      label: "Card payments accepted",
      data: { loanAmount: undefined },
      Component: Select,
      options: yesAndNoOptions,
      placeholder: "Do you accept card payments",
      selectedId: formData.cardPayments,
      defaultValue: formData.cardPayments
        ? formData.cardPayments
        : formatCardPaymentsDefaultValue() || {},
      onSelect: (id) => setFormData({ ...formData, cardPayments: id }),
      errors: validateFieldRequired(formData.cardPayments, validateRequired),
      isEmpty: !formData.cardPayments,
    },
    {
      name: "cardAmountOptions",
      label: "Annual card sales",
      data: { loanAmount: undefined },
      Component: Select,
      options: cardAmountOptions,
      placeholder: "Turnover from card sales",
      selectedId: formData.cardAmountOptions,
      onSelect: (id) => setFormData({ ...formData, cardAmountOptions: id }),
      visible: formData.cardPayments === "yes",
      errors: validateFieldRequired(
        formData.cardAmountOptions,
        validateRequired
      ),
      isEmpty: !formData.cardAmountOptions,
      defaultValue: formData.cardAmountOptions
        ? formData.cardAmountOptions
        : baseLead
          ? getCardReceipts(baseLead?.application)
          : undefined,
    },
    {
      name: "invoices",
      label: "Invoices sent to customers",
      data: { loanAmount: undefined },
      Component: Select,
      options: yesAndNoOptions,
      placeholder: "Do you issue invoices?",
      selectedId: formData.invoices,
      defaultValue: formData.invoices
        ? formData.invoices
        : formatInvoicesDefaultValue() || "",
      onSelect: (id) => setFormData({ ...formData, invoices: id }),
      single: formData.cardPayments !== "yes",
      errors: validateFieldRequired(formData.invoices, validateRequired),
      isEmpty: !formData.invoices,
    },
    {
      name: "businessAddress",
      label: "Business address",
      data: formData,
      Component: SinglePageFormAddress,
      placeholder: "Your business postcode",
      setData: setFormData,
      setValue: (val) => {
        setFormData({ ...formData, businessAddress: JSON.parse(val) });
        if (val !== "{}") {
          onSelectEnhancer("businessAddress");
        }
      },
      value: formData.businessAddress,
      defaultValue: JSON.stringify(formData.businessAddress),
      setError: () => null,
      visible: "My business is not listed here" === formData.businessData,
      errors: validatePostcode(
        formData.manual_postcode ?? formData.businessAddress?.postcode,
        skipBusAddress ? false : validateRequired
      ),
      isEmpty: !formData.manual_postcode,

    },
    {
      name: "houseNumber",
      label: "Building number/name",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) =>
        setFormData({ ...formData, houseNumber: e.target.value }),
      value: formData.houseNumber,
      visible: "My business is not listed here" === formData.businessData && formData?.businessAddress?.address === false,
      errors: validateFieldRequired(formData.houseNumber, validateRequired),
      isEmpty: !formData.houseNumber,
    },
    {
      name: "street",
      label: "Street",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) => setFormData({ ...formData, street: e.target.value }),
      value: formData.street,
      visible: "My business is not listed here" === formData.businessData && formData?.businessAddress?.address === false,
      errors: validateFieldRequired(formData.street, validateRequired),
      isEmpty: !formData.street,
    },
    {
      name: "city",
      label: "City",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) => setFormData({ ...formData, city: e.target.value }),
      value: formData.city,
      visible: "My business is not listed here" === formData.businessData && formData?.businessAddress?.address === false,
      errors: validateFieldRequired(formData.city, validateRequired),
      isEmpty: !formData.city,
    },
    {
      name: "county",
      label: "County",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) => setFormData({ ...formData, county: e.target.value }),
      value: formData.county,
      visible: "My business is not listed here" === formData.businessData && formData?.businessAddress?.address === false,
      errors: validateFieldRequired(formData.county, validateRequired),
      isEmpty: !formData.county,
    },
  ];

  const yourDetailsFields = [
    {
      name: "director",
      label: "Name",
      data: { loanAmount: undefined },
      Component: Select,
      options: directorOptions,
      placeholder: "Select your name",
      selectedId: formData.director,
      key: directorOptions.length,
      onSelect: (id) => setFormData({ ...formData, director: id }),
      single: true,
      visible: !!formData?.company?.id,
      errors: validateFieldRequired(formData.director, validateRequired),
      isEmpty: !formData.director,
      defaultValue: formData.changedCompany
        ? undefined
        : ( baseLead?.application?.director_name || "undefined"),
    },
    {
      name: "firstName",
      label: "First name",
      data: formData,
      Component: Input,
      type: "text",
      onChange: (event) =>
        setFormData({ ...formData, firstName: event.target.value }),
      value: formData.firstName,
      onBeforeInput: preventKeyPress,
      visible:
        !formData?.company?.id ||
        formData.director === "not_listed_here" ||
        (searchParams.get("pre") === "true" &&
          !!formData.director &&
          directorOptions?.length > 0 &&
          !formData?.company?.id &&
          !directorOptions.map((d) => d.id).includes(formData.director)),
      errors: directorNamesValidation(formData.firstName, validateRequired),
      isEmpty: !formData.firstName,
    },
    {
      name: "lastName",
      label: "Last name",
      type: "text",
      data: formData,
      Component: Input,
      onChange: (event) =>
        setFormData({ ...formData, lastName: event.target.value }),
      value: formData.lastName,
      onBeforeInput: preventKeyPress,
      visible:
        !formData?.company?.id ||
        formData.director === "not_listed_here" ||
        (searchParams.get("pre") === "true" &&
          !!formData.director &&
          !formData?.company?.id &&
          directorOptions?.length > 0 &&
          !directorOptions.map((d) => d.id).includes(formData.director)),
      errors: validateFieldRequired(formData.lastName, validateRequired),
      isEmpty: !formData.lastName,
    },
    {
      name: "jobPosition",
      label: "Job position",
      data: { loanAmount: undefined },
      Component: Select,
      options: jobPositionOptions,
      placeholder: "Select your job position",
      selectedId: formData.jobPosition,
      onSelect: (id) => setFormData({ ...formData, jobPosition: id }),
      visible:
        !formData?.company?.id || formData.director === "not_listed_here",
      errors: validateFieldRequired(formData.jobPosition, validateRequired),
      isEmpty: !formData.jobPosition,
      defaultValue: baseLead?.application?.job_position,
    },
    {
      name: "dob",
      label: "Date of birth",
      data: { loanAmount: undefined },
      Component: DateOfBirth,
      placeholder: "dd/mm/yyyy",
      data: { loanAmount: undefined },
      setValue: (val) => setFormData({ ...formData, dob: val }),
      value: formData.dob,
      single: true,
      errors: dobValidation(formData.dob, validateRequired),
      isEmpty: !formData.dob,
      defaultValue: baseLead?.application?.dob || undefined,
    },
    {
      name: "email",
      label: "Email address",
      data: { loanAmount: undefined },
      Component: Input,
      onChange: (event) =>
        setFormData({ ...formData, email: event.target.value }),
      errors: validateEmail(
        formData.email === null ? searchParams.get("email") : formData.email,
        validateRequired
      ),
      value: formData.email,
      inputMode: "email",
      isEmpty: !formData.email,
      defaultValue: searchParams.get("email"),
    },
    {
      name: "mobile",
      label: "Mobile number",
      data: { loanAmount: undefined },
      Component: Input,
      inputMode: "numeric",
      type: "tel",
      maxLength: "11",
      disabled: searchParams.get("pre") === "true" && searchParams.get('source') !== 'api',
      value: formData.mobileNumber,
      errors: validatePhoneNumber(
        formData.mobileNumber === null
          ? searchParams.get("phone")
          : formData.mobileNumber,
        validateRequired
      ),
      isEmpty: !formData.mobileNumber,
      defaultValue: searchParams.get("phone"),
      onChange: (event) =>
        setFormData({ ...formData, mobileNumber: event.target.value }),
      onKeyPress: (e) => {
        const isNumber = /^[0-9]$/i.test(e.key);
        if (!isNumber) {
          e.preventDefault();
        }
      },
    },
    {
      name: "residentialAddress",
      label: "Residential address",
      data: formData,
      Component: SinglePageFormAddress,
      placeholder: "Your residential postcode",
      setData: setFormData,
      setValue: (val) => {
        setFormData({ ...formData, residentialAddress: JSON.parse(val) });
        if (val !== "{}") {
          onSelectEnhancer("residentialAddress");
        }
      },
      value: formData.residentialAddress,
      defaultValue: JSON.stringify(formData.residentialAddress),
      decreaseTopSpacing: true,
      manualPostcodeName: "residential_postode_manual",
      setError: () => null,
      single: formData?.residentialAddress?.address !== false,
      errors: validatePostcode(
        formData.residential_postode_manual ?? formData.residentialAddress?.postcode,
        validateRequired
      ),
      isEmpty: !formData.residential_postode_manual,
    },
    {
      name: "residentiaHouseNumber",
      label: "House number/name",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) =>
        setFormData({ ...formData, residentiaHouseNumber: e.target.value }),
      value: formData.residentiaHouseNumber,
      visible: formData?.residentialAddress?.address === false,
      errors: validateFieldRequired(
        formData.residentiaHouseNumber,
        validateRequired
      ),
      isEmpty: !formData.residentiaHouseNumber,
    },
    {
      name: "residentialStreet",
      label: "Street",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) =>
        setFormData({ ...formData, residentialStreet: e.target.value }),
      value: formData.residentialStreet,
      visible: formData?.residentialAddress?.address === false,
      errors: validateFieldRequired(
        formData.residentialStreet,
        validateRequired
      ),
      isEmpty: !formData.residentialStreet,
    },
    {
      name: "residentialCity",
      label: "City",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) =>
        setFormData({ ...formData, residentialCity: e.target.value }),
      value: formData.residentialCity,
      visible: formData?.residentialAddress?.address === false,
      errors: validateFieldRequired(formData.residentialCity, validateRequired),
      isEmpty: !formData.residentialCity,
    },
    {
      name: "residentialCounty",
      label: "County",
      data: formData,
      Component: Input,
      placeholder: "",
      onChange: (e) =>
        setFormData({ ...formData, residentialCounty: e.target.value }),
      value: formData.residentialCounty,
      visible: formData?.residentialAddress?.address === false,
      single: true,
      errors: validateFieldRequired(
        formData.residentialCounty,
        validateRequired
      ),
      isEmpty: !formData.residentialCounty,
    },
    {
      name: "residentialStatus",
      label: "Residential status",
      data: { loanAmount: undefined },
      Component: Select,
      options: residentialStatusOptions,
      placeholder: "Are you a homeowner?",
      selectedId: formData.residentialStatus,
      onSelect: (id) => setFormData({ ...formData, residentialStatus: id }),
      errors: validateFieldRequired(
        formData.residentialStatus,
        validateRequired
      ),
      isEmpty: !formData.residentialStatus,
      defaultValue:
        getResidentialStatus(baseLead?.application?.residential_status) ||
        undefined,
    },
    {
      name: "directorHomeStatus",
      label: "Director's Home Status",
      placeholder: "Any other Director a homeowner?",
      options: yesAndNoOptions,
      Component: Select,
      selectedId: formData.directorHomeStatus,
      onSelect: (id) => setFormData({ ...formData, directorHomeStatus: id }),
      errors: validateFieldRequired(
        formData.directorHomeStatus,
        validateRequired
      ),
      isEmpty: !formData.directorHomeStatus,
      visible: formData?.residentialStatus === "no",
      defaultValue:
        getDirectorHomeStatus(
          baseLead?.application?.other_director_home_status
        ) || undefined,
    },
    {
      name: "propertyValue",
      label: "Property value",
      data: { loanAmount: undefined },
      placeholder: "Estimated property value",
      Component: Currency,
      setValue: (val) => setFormData({ ...formData, propertyValue: val }),
      visible:
        formData.residentialStatus !== "no" &&
        formData.residentialStatus !== "",
      value: formData.propertyValue,
      errors: validateFieldRequired(formData.propertyValue, validateRequired),
      isEmpty: !formData.propertyValue,
    },
  ];

  function removeKeys(obj, keysToRemove) {
    const newObj = JSON.parse(JSON.stringify(obj));

    keysToRemove.forEach((key) => {
      delete newObj[key]; // Remove the key from the copied object
    });

    return newObj;
  }

  const prepareFinishForm = () => {
    const fields = [
      ...baseFields,
      ...businessDetailsFields,
      ...yourDetailsFields,
    ];
    const hiddenFields = fields.filter((f) => f.visible === false);

    const clonedFormData = removeKeys(
      formData,
      hiddenFields.map((f) => f.name)
    );
    return clonedFormData;
  };

  const handleFinishAgentForm = async () => {
    const clonedFormData = prepareFinishForm();

    const [leadId, data] = await handleSubmitForm(
      clonedFormData,
      null,
      null,
      false,
      submitType.current === SUBMIT_TYPES.SAVE_SMS,
      searchParams.get("pre") === "true",
      searchParams.get("leadId"),
      searchParams.get('source')
    );

    return [leadId, data];
  };

  const handleFinishForm = async () => {
    const clonedFormData = prepareFinishForm();
    const [leadId, data] = await handleSubmitForm(
      clonedFormData,
      otp,
      token,
      true,
      false,
      searchParams.get("pre") === "true",
      location?.state?.leadId,
      searchParams.get('source')
    );
    navigate("/dashboard/success", {
      state: { ...data, isSubmitSuccess: true, id: leadId },
    });
  };

  useEffect(() => {
    setTimeout(handleErrors);
  }, [currentValidation]);

  useEffect(() => {
    async function getData(cid) {
      const data = await getCompanyData(cid);
      setDirectorOptions([
        ...data
          .filter((d) => !!d.director)
          .map((d) => ({
            id: d.value,
            text: d.label,
          })),
        {
          id: "not_listed_here",
          text: "My name is not listed",
        },
      ]);
      if (baseLead) {
        setIsLoading(false);
      }
    }
    const cid = formData?.company?.id;
    if (cid) getData(cid);
    else setTimeout(() => setIsLoading(false), 2000);
  }, [formData.company]);

  return !isLoading ? (
    <ConfigProvider theme={THEME}>
      <div className="single-page-form">
        <Row
          className={styles.imageContainer}
          justify="space-between"
          align={"middle"}
        >
          <img
            className="logo"
            src="/assets/logo.svg"
            alt="loantube logo"
            style={{
              height: isMobile ? "28px" : "clamp(40px, 4.4vh, 60px)",
            }}
          />
          <PhoneNumberInfo
            isMobile={isMobile}
            style={{ fontSize: isMobile && "14px" }}
            iconStyle={{
              width: isMobile ? "12px" : "13.4px",
              top: "0.5px",
              marginRight: "6px",
            }}
          />
        </Row>
        <div style={{ zIndex: "10000000", position: "relative" }}>
          <FormPopup
            isMobile={isMobile}
            visible={done}
            onClose={() => setDone(false)}
          >
            <div
              style={{
                maxWidth: "600px",
                margin: "0 auto",
                padding: "32px 8px 28px",
              }}
            >
              <OTPStep
                data={{ mobile_number: formData.mobileNumber }}
                setValue={(o) => {
                  setOtp(o);
                }}
                errors={[]}
                value={otp}
                isMobile={isMobile}
                onContinue={handleFinishForm}
                onCancel={() => setDone(false)}
                onResend={() => null}
                isLoginEnabled={otp.length === 4}
                submitText={"Submit"}
                title={"Verify your mobile number"}
              />
            </div>
          </FormPopup>
        </div>
        <div style={{ zIndex: "10000000", position: "relative" }}>
          <FormPopup
            isMobile={false}
            visible={agentLeadId}
            onClose={() => setAgentLeadId(null)}
            desktopOnly={true}
          >
            <div
              style={{
                maxWidth: "600px",
                margin: "0 auto",
                padding: isMobile ? "2px 8px 16px" : "32px 8px 28px",
              }}
            >
              <Typography.Title
                style={{
                  display: "block",
                  fontWeight: "600",
                  fontSize: isMobile ? "20px" : "28px",
                  marginBottom: "0",
                  paddingRight: "24px",
                  marginTop: isMobile && "12px",
                }}
              >
                Application saved successfully
              </Typography.Title>
              <div>
                <Row
                  justify={isMobile ? "center" : "space-between"}
                  align={"middle"}
                  style={{
                    marginTop: isMobile ? "32px" : "72px",
                    padding: "0",
                    flexDirection: isMobile && "column-reverse",
                    gap: isMobile ? "2px" : "24px",
                  }}
                >
                  <StepForwardButton
                    enabled={true}
                    hasIcon={false}
                    isMobile={true}
                    descale={true}
                    onClick={handleRefresh}
                  >
                    Create new application
                  </StepForwardButton>
                  <StepForwardButton
                    enabled={true}
                    hasIcon={false}
                    isMobile={true}
                    descale={true}
                    onClick={() => {
                      let url = `${process.env.REACT_APP_CRM_URL}/leads/${agentLeadId}`
                      window.open(url, '_blank')
                    }
                    }
                  >
                    View application
                  </StepForwardButton>
                </Row>
              </div>
            </div>
          </FormPopup>
        </div>
        {searchParams.get("pre") === "true" && (
          <Typography.Title className={styles.pageTitle}>
            Almost There! Review your details to view Offers
          </Typography.Title>
        )}
        <div className={styles.container}>
          <div className={styles.section} style={{ zIndex: 8000 }}>
            <Typography.Title className={styles.sectionTitle}>
              Your loan application
            </Typography.Title>
            <FieldListRenderer
              fieldList={baseFields}
              isMobile={isMobile}
              onSelectEnhancer={onSelectEnhancer}
              isValidated={currentValidation > 0}
            />
          </div>
          <div className={styles.section} style={{ zIndex: 7999 }}>
            <Typography.Title className={styles.sectionTitle}>
              Business details
            </Typography.Title>
            <FieldListRenderer
              fieldList={businessDetailsFields}
              isMobile={isMobile}
              onSelectEnhancer={onSelectEnhancer}
              isValidated={currentValidation > 0}
            />
          </div>
          <div className={styles.section} style={{ zIndex: 7998 }}>
            <Typography.Title className={styles.sectionTitle}>
              Your details
            </Typography.Title>
            <FieldListRenderer
              fieldList={yourDetailsFields}
              isMobile={isMobile}
              onSelectEnhancer={onSelectEnhancer}
              isValidated={currentValidation > 0}
            />
          </div>
          <div className={styles.section} style={{ paddingTop: "16px" }}>
            <div style={{ maxWidth: "1200px" }}>
              <Typography.Title
                style={{
                  fontSize: "16px",
                  marginBottom: "12px",
                }}
              >
                By clicking find your loan:
              </Typography.Title>
              <AcceptTerms noPadding={true} />
            </div>
          </div>
          {!variation ? (
            <Row
              justify={"center"}
              style={{ marginTop: isMobile ? "42px" : "56px" }}
            >
              <StepForwardButton
                enabled={true}
                hasIcon={false}
                isMobile={isMobile}
                onClick={handleSubmit}
                style={
                  isMobile && {
                    padding: "7px 32px",
                    borderRadius: "12px",
                  }
                }
              >
                Find your loan
              </StepForwardButton>
            </Row>
          ) : (
            <Row
              justify={isMobile ? "center" : "space-between"}
              align={"middle"}
              style={{
                marginTop: isMobile ? "42px" : "56px",
                padding: "0",
                flexDirection: isMobile && "column-reverse",
              }}
            >
              <StepForwardButton
                enabled={true}
                hasIcon={false}
                isMobile={isMobile}
                onClick={() => handleSubmitSave(SUBMIT_TYPES.SAVE)}
              >
                Save without SMS
              </StepForwardButton>
              <StepForwardButton
                enabled={true}
                hasIcon={false}
                isMobile={isMobile}
                style={{ marginBottom: "20px" }}
                onClick={() => handleSubmitSave(SUBMIT_TYPES.SAVE_SMS)}
              >
                Save with SMS
              </StepForwardButton>
            </Row>
          )}
        </div>
        <Footer isMobile={isMobile} />
      </div>
    </ConfigProvider>
  ) : (
    <div></div>
  );
};

export default SinglePageFormWrapper;
