import SinglePageForm from ".";
import { useState, useEffect } from "react";
import { whoami } from "./services";
import { useNavigate } from "react-router-dom";

const SinglePageFormPrivate = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);

  useEffect(() => {
    async function call() {
      const response = await whoami();
      setRole(response.role);
      if (response.role === 'agent' || response.role === 'admin') {
        setLoading(false);
      }
      else {
        navigate('/login');
      }
    }
    call();
  }, []);

  return loading ? <div></div> : <SinglePageForm role={role} variation />;
};

export default SinglePageFormPrivate;
