import FormPopup from "../../../../components/CustomerComponents/FormPopup";
import { Typography, Spin } from "antd";
import { useEffect, useRef, useState } from "react";
import StepForwardButton from "../../../../components/CustomerComponents/StepForwardButton";
import PhoneIcon from "../../../../components/CustomerComponents/PhoneIcon";
import styles from "./redirectFormStyle.module.css";
import { useNavigate } from "react-router-dom";
import confetti from "canvas-confetti";
import { COLORS } from "../../../../core/constants";

const TEXT_CONTENT_TO_STATE = {
  LOADING: {
    TITLE: "We are fetching your offers from Funding Circle",
    SUBTITLE: "This can take up to 60 seconds",
  },
  PROCESSING: {
    TITLE: "Congratulations! Your application has been submitted successfully.",
    SUBTITLE: "Please upload documents to complete the process.",
    CTA: "Upload Documents",
  },
  CONFIRMED: {
    TITLE: "Congratulations! Your loan is approved",
    SUBTITLE:
      "You'll shortly receive a call from LoanTube to sign the loan agreement.",
    THIRD_TEXT: `Working hours: Mon - Fri 9am to 6pm`,
  },
  DECLINED: {
    TITLE: "Instant offer couldn’t be generated from Funding Circle.",
    SUBTITLE: "Additional documents are required for the approval.",
    CTA: "Upload Documents",
  },
  MORE_ACTION: {
    TITLE: "Congratulations! Your application has passed initials checks",
    THIRD_TEXT: "Please upload documents to complete the process.",
    CTA: "Upload Documents",
  },
};

const STATES = {
  LOADING: "LOADING",
  CONFIRMED: "CONFIRMED",
  PROCESSING: "PROCESSING", //PENDING
  DECLINED: "DECLINED",   
  MORE_ACTION: "MORE_ACTION", //ACTION_REQUIRED
};

const responseModalMap = {
  "action_required": "MORE_ACTION",
  "confirmed" : "CONFIRMED",
  "pending": "PROCESSING",
  "declined": "DECLINED"
}
function getNextState(currentState) {
  const stateKeys = Object.keys(STATES);
  const currentIndex = stateKeys.indexOf(currentState);

  if (currentIndex === -1) {
    throw new Error("Invalid state provided.");
  }

  const nextIndex = (currentIndex + 1) % stateKeys.length;
  return STATES[stateKeys[nextIndex]];
}

const RedirectForm = ({ visible, onClose, isMobile, onBack, isLoading, handleSubmitOffer, redirectState }) => {
  const imageSrc = `/assets/logos/fundingcircle.png`;
  const [formState, setFormState] = useState(STATES.LOADING);
  const navigate = useNavigate();
  const canvasRef = useRef(null);

  useEffect(() => {
    if(redirectState){
      const state = responseModalMap[redirectState.popupType] || "LOADING"
      setFormState(state);
    }
  },[redirectState])


  useEffect(() => {
    if(visible){
      handleSubmitOffer();
    }
  },[visible])

  useEffect(() => {
    // if (visible === true) {
    //   setTimeout(() => {
    //     if (formState === STATES.LOADING) {
    //       setFormState(STATES.PROCESSING);
    //     }
    //   }, 10000);
    // }
    if (visible === false) {
      setFormState(STATES.LOADING);
    }

    if (canvasRef.current) {
      canvasRef.current.confetti =
        canvasRef.current.confetti ||
        confetti.create(canvasRef.current, { resize: true });
    }
  }, [visible]);

  const handleSubmit = () => {
    navigate("/dashboard/your-documents");
  };

  const handleClose = () => {
    setFormState(getNextState(formState));
  };

  // var end = Date.now() + (1 * 3000);

  // var colors = ['#C60029'];

  // function frame() {
  //   canvasRef.current.confetti({
  //     particleCount: 1,
  //     angle: 60,
  //     spread: 55,
  //     origin: { x: -0.05 },
  //     colors: colors,
  //   });

  //   canvasRef.current.confetti({
  //     particleCount: 1,
  //     angle: 120,
  //     spread: 55,
  //     origin: { x: 1.05 },
  //     colors: colors,
  //   });

  //   if (Date.now() < end) {
  //     requestAnimationFrame(debounce(frame, 8));
  //   }
  // }

  // var duration = 600 * 1000;
  // var animationEnd = Date.now() + duration;
  // var skew = 1;

  // function randomInRange(min, max) {
  //   return Math.random() * (max - min) + min;
  // }

  // const confettiColors = [
  //   "#FF5733", // Red
  //   "#33FF57", // Green
  //   "#3357FF", // Blue
  //   "#FF33A1", // Pink
  //   "#FFFF33", // Yellow
  //   "#33FFF5", // Cyan
  //   "#FF8633", // Orange
  //   "#8D33FF", // Purple
  // ];
  // let frameIteration = 0;

  // function frame() {
  //   var timeLeft = animationEnd - Date.now();
  //   var ticks = Math.max(200, 500 * (timeLeft / duration));
  //   skew = Math.max(0.8, skew - 0.001);

  //   canvasRef.current.confetti({
  //     particleCount: 1,
  //     startVelocity: 0,
  //     ticks: ticks,
  //     origin: {
  //       x: Math.random(),
  //       // since particles fall down, skew start toward the top
  //       y: Math.random() * skew - 0.5,
  //     },
  //     colors: ["#C60029"],
  //     gravity: randomInRange(0.4, 0.6),
  //     scalar: randomInRange(0.4, 1),
  //     drift: randomInRange(-0.4, 0.4),
  //   });

  //   if (timeLeft > 0) {
  //     requestAnimationFrame(debounce(frame, 3));
  //   }
  // }

  var count = 200;
  var defaults = {
    origin: { y: 1 },
  };

  function fireCanvasConfetti(particleRatio, opts) {
    canvasRef.current.confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
      colors: ["#C60029", "#FFD700"],
    });
  }

  function animateCanvas (iteration) {
    if (iteration === 2) return;

    fireCanvasConfetti(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fireCanvasConfetti(0.2, {
      spread: 60,
    });
    fireCanvasConfetti(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fireCanvasConfetti(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fireCanvasConfetti(0.1, {
      spread: 120,
      startVelocity: 45,
    });

    setTimeout(() => {
      animateCanvas(iteration + 1);
    }, 6000);
  }

  useEffect(() => {
    if (!canvasRef.current) return;

    if (formState === STATES.CONFIRMED) {
      animateCanvas(0);
    }
  }, [formState]);

  return (
    <FormPopup
      visible={visible}
      isMobile={isMobile}
      onClose={() => {
        window.location.reload();
        onClose();
      }}
      persistent={true}
    >
      <canvas
        style={{
          position: "absolute",
          inset: "0",
          width: "100%",
          height: "100%",
          pointerEvents: "none",
          opacity: formState === STATES.LOADING ? 0 : 1,
        }}
        ref={canvasRef}
      ></canvas>
      <div
        className="single-page-form scrollbar light"
        style={{ minWidth: !isMobile && "560px" }}
      >
        {formState !== STATES.LOADING ? (
          <div
            style={{
              marginBottom: "32px",
              marginTop: "48px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={imageSrc}
              style={{
                height: "56px",
              }}
            />
          </div>
        ) : (
          <Typography.Title
            style={{
              fontSize: isMobile ? "20px" : "24px",
              marginTop: isMobile ? "40px" : "40px",
              marginBottom: "24px",
              marginLeft: isMobile && "8px",
              textAlign: "center",
            }}
          >
            {TEXT_CONTENT_TO_STATE[formState].TITLE}
          </Typography.Title>
        )}
        {formState !== STATES.LOADING && (
          <div style={{ textAlign: "center" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography.Title
                style={{
                  fontSize:
                    formState === STATES.CONFIRMED
                      ? "26.1px"
                      : isMobile
                      ? "18px"
                      : "22px",
                  maxWidth: "520px",
                  marginTop: isMobile && "4px",
                  marginBottom: "24px",
                  marginLeft: isMobile && "8px",
                  textAlign: "center",
                }}
              >
                 {TEXT_CONTENT_TO_STATE[formState].TITLE}
              </Typography.Title>
            </div>

            {redirectState?.lines?.[2]?.text && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Typography.Text
                  style={{
                    fontSize:
                      formState === STATES.CONFIRMED
                        ? "20px"
                        : isMobile
                        ? "16px"
                        : "18px",
                    maxWidth: "500px",
                    display: "block",
                  }}
                >
                   {TEXT_CONTENT_TO_STATE[formState].SUBTITLE}
                </Typography.Text>
              </div>
            )}
            {formState === STATES.CONFIRMED && (
              <div style={{ marginTop: "24px", marginBottom: "6px" }}>
                <a href={isMobile ? "tel:020-8088-5001" : "javascript:void(0)"}>
                  <StepForwardButton enabled>
                    <span
                      style={{
                        color: COLORS.white,
                        width: "clamp(14px, 1.8518vh, 20px)",
                        marginRight: "12px",
                        position: "relative",
                        top: "1.5px",
                      }}
                    >
                      <PhoneIcon />
                    </span>
                    020 8088 5001
                  </StepForwardButton>
                </a>
              </div>
            )}
            <div
              style={{
                marginBottom: "32px",
                display: isMobile && "flex",
                justifyContent: "center",
                marginTop: isMobile && "12px",
              }}
            >
              <Typography.Text
                style={{
                  fontSize:
                    formState === STATES.MORE_ACTION
                      ? isMobile
                        ? "16px"
                        : "18px"
                      : "14px",
                  display: "block",
                  marginBottom:
                    formState === STATES.CONFIRMED ? "26px" : undefined,
                  maxWidth: isMobile && "350px",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: TEXT_CONTENT_TO_STATE[formState].THIRD_TEXT,
                  }}
                ></span>
              </Typography.Text>
            </div>
            {redirectState?.cta && formState !== STATES.CONFIRMED && (
              <StepForwardButton
                style={{ marginBottom: "32px" }}
                isMobile={isMobile}
                enabled={true}
                onClick={handleSubmit}
              >
                 {TEXT_CONTENT_TO_STATE[formState].CTA}
              </StepForwardButton>
            )}
          </div>
        )}
        {formState === STATES.LOADING && (
          <div
            style={{
              position: "relative",
              padding: isMobile && "0 16px",
              margin: isMobile && "0 auto",
              pointerEvents: isLoading && "none",
              opacity: isLoading && "0.1",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "60px",
                alignItems: "center",
              }}
            >
              <img
                className="logo"
                src="/assets/logo.svg"
                alt="loantube logo"
                style={{ height: isMobile ? "29px" : "clamp(27px, 4vh, 44px)" }}
              />
              <img
                src={imageSrc}
                style={{
                  height: isMobile ? "34px" : "clamp(36px, 5.1vh, 54px)",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "60px",
                alignItems: "center",
                position: "relative",
                marginBottom: "60px",
              }}
            >
              <div className={styles.animated}></div>
              <div className={styles.ball}></div>
              <div className={styles.middle}>
                <div className={styles.ball}></div>
              </div>
              <div className={styles.line}></div>
              <div className={styles.ball}></div>
            </div>
            <div style={{ textAlign: "center", marginBottom: "60px" }}>
              <Typography.Text
                style={{
                  fontSize: isMobile ? "16px" : "18px",
                }}
              >
                This can take up to 60 seconds
              </Typography.Text>
            </div>
          </div>
        )}

        {isLoading && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              top: "140px",
            }}
          >
            <Spin />
          </div>
        )}
      </div>
    </FormPopup>
  );
};

export default RedirectForm;
